import React, { useContext } from "react";
import { ScrollView, View } from "react-native";
import * as R from "ramda";
import ReceiptDetailSection, {
  DetailItem,
} from "../../../components/v2/ReceiptsDetailSection";
import { translate } from "../../../lib/i18n";
import { formatCurrency, getTimeSpent } from "../../../lib/utils";
import ReceiptHeader from "./ReceiptHeader";
import { styles } from "./styles";
import { buildChargeData, buildTaxBreakdownTable } from "./utils";
import ReceiptFooter from "./ReceiptFooter";
import StyledScreen from "src/components/StyledScreen";
import { config } from "src/lib/config";
import { MasterContext } from "src/lib/masterContext";
import { TaxBreakdownTable } from "./TaxBreakdownTable";
import { color2 } from "src/styles";

export const ReceiptDetail = ({
  order,
  onContest,
}: {
  order: ApiOrderDetail;
  onContest: () => void;
}) => {
  const {
    featureFlags: {
      hideProcessTime,
      productsTaxEnabled,
      orderDetailsShowDetailsAtTheTop,
      orderDetailsShowFooterOrderId,
      contestingEnabled,
      hideCardBrand,
      hideTaxBreakdown,
    },
    appConfig: { timezone },
    flavor,
  } = useContext(MasterContext);
  const created = flavor.formatDateTime(order.draftCreatedAt, timezone);
  const processTime = order
    ? getTimeSpent(order.draftCreatedAt, order.processedAt)
    : "";

  const dataProduct = order.lineItems.flatMap((item) => {
    const price = order.taxesIncluded
      ? item.totalPrice
      : item.subtotalPrice ?? item.totalPrice;
    const isDiscount = Number(price) < 0;
    const productLine: DetailItem[] = [
      {
        title: item.name,
        value:
          formatCurrency(price, order.currencyCode, item.quantity) +
          (!hideTaxBreakdown && !R.isNil(item.taxRates)
            ? " " + item.taxRates?.map((tax) => tax.name).join(" ")
            : ""),
        status: item.status,
        leftViewStyle: {
          wordBreak: "break-word",
          color: isDiscount ? color2.DISCOUNT : color2.BLUE_DARK,
        },
        rightViewStyle: {
          color: isDiscount ? color2.DISCOUNT : color2.BLUE_DARK,
        },
      },
    ];
    if (item.quantity > 1) {
      productLine.push({
        title: `${item.quantity} x ${formatCurrency(
          `${Number(price) / item.quantity}`,
          order.currencyCode
        )}`,
        leftViewStyle: { marginLeft: "3rem" },
      });
    }
    return productLine;
  });

  const chargeData = buildChargeData(
    order,
    formatCurrency,
    productsTaxEnabled,
    hideTaxBreakdown
  );

  const dateAndTimeData = [
    {
      title: translate("receiptDetailScreen.date"),
      value: `${created.date} ${created.time}`,
    },
  ] as DetailItem[];

  if (!hideProcessTime) {
    dateAndTimeData.push({
      title: translate("receiptDetailScreen.processTime"),
      value: processTime,
    });
  }

  const showReportProblem =
    contestingEnabled &&
    order.status === "paid" &&
    order.lineItems.filter((i) => i.rin).length === order.lineItems.length;

  let footerText = config.messages.footerPaid;
  if (order.status === "contested") {
    footerText = config.messages.footerInReview;
  } else if (order.status === "reviewed") {
    footerText = config.messages.footerReviewed;
  }

  const taxBreakdownItems = R.values(order.taxBreakdownTableData ?? {});
  const taxBreakdownTable = buildTaxBreakdownTable(order, taxBreakdownItems);

  const cardBrand = order.paymentInfo?.paymentInstrument.cardBrand;
  const cardData = cardBrand && [
    {
      title: cardBrand,
      value: formatCurrency(order.totalPrice, order.currencyCode),
      valueTall: true,
    },
  ];

  const getTaxSection = () => {
    if (hideTaxBreakdown || R.isNil(order.taxBreakdownTableData)) {
      return <ReceiptDetailSection name="charge" data={chargeData} />;
    }
    return <TaxBreakdownTable data={taxBreakdownTable} />;
  };

  return (
    <StyledScreen>
      <ScrollView style={styles.scroll}>
        <View style={styles.content}>
          <ReceiptHeader order={order} />
          <ReceiptDetailSection name="product" data={dataProduct} />
          {getTaxSection()}
          {!hideCardBrand && cardData && (
            <ReceiptDetailSection name="card" data={cardData} />
          )}
          {!orderDetailsShowDetailsAtTheTop && (
            <ReceiptDetailSection name="date" data={dateAndTimeData} />
          )}
        </View>
        <ReceiptFooter
          orderId={orderDetailsShowFooterOrderId && order.id}
          showSubmit={showReportProblem}
          extraText={order.extraText}
          onSubmit={() => {
            onContest();
          }}
          footerText={footerText}
        />
      </ScrollView>
    </StyledScreen>
  );
};
