import React from "react";
import { BrowserRouter } from "react-router-dom";

import { MasterSettingsProvider } from "./lib/masterContext/masterSettingsProvider";
import { FlavorStyles } from "./styles/flavorStyles";
import { AppRoutes } from "./views/AppRoutes";

export const App: React.FC = () => {
  return (
    <div className="App">
      <MasterSettingsProvider>
        <FlavorStyles>
          <BrowserRouter>
            <AppRoutes />
          </BrowserRouter>
        </FlavorStyles>
      </MasterSettingsProvider>
    </div>
  );
};
