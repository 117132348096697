import React from 'react'
import { Flavor } from './flavor'
import { color2 } from '../../styles'
import { LogoVerizon } from '../../components/LogoVerizon'

class VerizonFlavor extends Flavor {
  getCode() {
    return '51-17'
  }

  getName() {
    return 'LN BottleRock Express'
  }

  getFullName() {
    return 'LN BottleRock Express'
  }

  getMainColor() {
    return color2.PENSKE_GRAY
  }

  getLinearGradientBackground() {
    return [color2.WHITE, color2.PENSKE_GRAY]
  }

  getDashLineColor() {
    return color2.PENSKE_GRAY
  }

  getCircleLogo() {
    return <LogoVerizon />
  }

  getAPIEndpoint() {
    return 'https://oasis-api.51-17.client.aifi.io'
  }

  getStripePublicKey() {
    return 'pk_live_51H9zamCx0HxISgNmdtaXWSW9LDzSEqCXoYEmydnPvigLM5K7cdwUgvVuCf5LtPtVE9EPIdzpXmx0IDkLeQ3vmNXG00wPq0XoPv'
  }
}

const Singleton = new VerizonFlavor()
export default Singleton
