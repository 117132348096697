import React from "react";
import { NavigationParams, NavigationScreenProp } from "react-navigation";
import { Linking } from "react-native";
import { Flavor } from "./flavor";
import { color2 } from "src/styles";
import { LogoMorrisons } from "src/components/LogoMorrisons";
// import { OnboardingMorrisons } from "src/views/Onboarding/morrisons";

class MorrisonsFlavor extends Flavor {
  getCode() {
    return "29-12";
  }

  getName() {
    return "Morrisons";
  }

  getFullName() {
    return "Morrisons";
  }

  getMainColor() {
    return color2.MORRISONS;
  }

  getLinearGradientBackground() {
    return [color2.WHITE, color2.MORRISONS_LIGHT];
  }

  getCircleLogo() {
    return <LogoMorrisons />;
  }

  getAPIEndpoint() {
    return "https://oasis-api.29-12.client.aifi.io";
  }

  getStripePublicKey() {
    return "pk_live_51IQauTCxQKhXnn3JaT256Z9pGvDJcSNak7m3fOdJHt7Xmm1TfKa0j1SDCiysVVsgf5kEtuFMMFaatZxUBE5qUXy300luBaEGeN";
  }

  // getOnboarding(navigation: any) {
  //   return <OnboardingMorrisons navigation={navigation} />;
  // }

  getAddress() {
    return "Wm Morrison Supermarket\nHilmore House\nGain Lane\nBradford\nBD3 - 7DL";
  }

  getAddressPlaceholder() {
    return "71 Gain Ln, Bradford BD3 7DL";
  }

  getTelephone() {
    return null;
  }

  getSupportEmail() {
    return null;
  }

  getFeatureFlags() {
    return {
      ...super.getFeatureFlags(),
      isAddressEnabled: true,
      hideProcessTime: true,
      aifiFooterText: false,
    };
  }

  getTouLink() {
    return "https://www.morrisons.com/help/morrisons-pick-up/terms-and-conditions/";
  }

  getAboutUsLink() {
    return "https://www.morrisons.com/help/morrisons-pick-up";
  }

  getFaqAction(_navigation: NavigationScreenProp<NavigationParams>) {
    Linking.openURL("https://www.morrisons.com/help/morrisons-pick-up");
  }
}

const Singleton = new MorrisonsFlavor();
export default Singleton;
