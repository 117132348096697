import { StyleSheet } from "react-native";
import { color2, dpToPx } from "../../../../styles";
import { font } from "../../../../styles/themes";

export const styles = StyleSheet.create({
  addressWrapper: {
    paddingVertical: 10,
    paddingHorizontal: 25,
    alignItems: "center",
  },
  star: {
    fontSize: 15,
    fontFamily: font.family.ibmPlexMonoMedium,
    color: color2.BLUE_DARK,
    letterSpacing: 3,
  },
  address: {
    fontSize: 12,
    fontFamily: font.family.ibmPlexMonoMedium,
    color: color2.BLUE_DARK,
    lineHeight: 18,
    textAlign: "center",
    marginTop: 8,
  },
  submitButton: {
    marginTop: dpToPx(15),
    marginBottom: dpToPx(15),
  },
  extraText: {
    fontFamily: font.family.ibmPlexMonoMedium,
    margin: "auto",
    width: "338px",
    display: "block",
    wordBreak: "break-all",
  },
});
