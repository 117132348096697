import React, { FC, useContext } from "react";
import { IFlavor } from "../flavors/flavorTypes";
import { MasterContext } from "../masterContext";

export interface IUseFlavor {
  flavor: IFlavor;
}

export const useFlavor = <T extends object>(
  WrappedComponent: FC<T & IUseFlavor>
) => {
  const ComponentWithFlavor: FC<T> = (props) => {
    const { flavor } = useContext(MasterContext);
    return <WrappedComponent {...props} flavor={flavor} />;
  };
  return ComponentWithFlavor;
};
