import React from 'react'
import { Flavor } from './flavor'
import { IFeatureFlags } from '../../../../shared/interface';
import { color2 } from "src/styles";
import { timezoneDate } from '../utils';
import {LogoLevy} from "../../components/LogoLevy";

class LevyFlavor extends Flavor {
    getCode() {
        return '63-17'
    }

    getName() {
        return 'Ford Field Stadium'
    }

    getFullName() {
        return 'Ford Field Stadium'
    }

    getMainColor() {
        return color2.LEVY_MAIN
    }

    getFeatureFlags(): IFeatureFlags {
        return {
            ...super.getFeatureFlags(),
        }
    }

    getLinearGradientBackground() {
        return [color2.WHITE, color2.LEVY_MAIN]
    }

    getDashLineColor() {
        return color2.LEVY_MAIN
    }

    getAPIEndpoint() {
        return 'https://oasis-api.63-17.client.aifi.io'
    }

    getStripePublicKey() {
        return 'pk_test_51ILX3HDSugCw1CsrdGMZYeBmb5xxpi9TYqP7srrQ65SeNb8HsGxZwZIcYUCcEN0B7lemHcDjLUXfaq6JShG3pAou007swfkbxy'
    }

    getCircleLogo() {
        return <LogoLevy />
    }

    formatDateTime(dateTime: string, timezone: string) {
        const date = timezoneDate(dateTime, timezone)
        const formatDate = "MM-DD-YYYY";
        const formatTime = "HH:mm";

        return {
            date: date.format(formatDate),
            time: date.format(formatTime),
        };
    }
}

const Singleton = new LevyFlavor()
export default Singleton
