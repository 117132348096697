import { color2 } from "../../styles";
import { Flavor } from "./flavor";

class AiFiFlavor extends Flavor {
  getName() {
    return "AiFi";
  }

  getFullName() {
    return "AiFi Inc.";
  }

  getMainColor() {
    return color2.BLUE_AIFI;
  }

  getLinearGradientBackground() {
    return [color2.WHITE, color2.LIGHT_BLUE];
  }

  getDashLineColor() {
    return color2.LIGHT_BLUE2;
  }

  getAPIEndpoint() {
    return "";
  }

  getStripePublicKey() {
    return "pk_test_51ILX3HDSugCw1CsrdGMZYeBmb5xxpi9TYqP7srrQ65SeNb8HsGxZwZIcYUCcEN0B7lemHcDjLUXfaq6JShG3pAou007swfkbxy";
  }
}

const Singleton = new AiFiFlavor();
export default Singleton;
