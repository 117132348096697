import React from "react";
import { View, Dimensions, StyleProp, ViewStyle } from "react-native";
import { IUseFlavor, useFlavor as withFlavor } from "src/lib/hooks/useFlavor";
import { styles } from "./style";

type IDashLine = IUseFlavor & {
  width?: number;
  spacing?: number;
  style?: StyleProp<ViewStyle>;
};

const screenWidth = Math.ceil(Dimensions.get("window").width);

const DashLineEl: React.FC<IDashLine> = ({
  width = 6,
  spacing = 7,
  style,
  flavor,
}) => {
  const css = styles(flavor.getMainColor());
  const fragmentCounts = screenWidth / (width + spacing);

  const lines = Array.from({ length: fragmentCounts }, (_, idx) => (
    <View key={idx} style={[css.line, { width, marginRight: spacing }]} />
  ));

  return <View style={[css.wrapper, style]}>{lines}</View>;
};

export const DashLine = withFlavor(DashLineEl);
