import React from "react";
import { Flavor } from "./flavor";
import { color2 } from "src/styles";
import { LogoVerizonUltra } from "src/components/LogoVerizonUltra";

class VerizonUltraFlavor extends Flavor {
  getName() {
    return "VerizonUltra";
  }

  getFullName() {
    return "VerizonUltra";
  }

  getMainColor() {
    return color2.VERIZONULTRA_MAIN;
  }

  getLinearGradientBackground() {
    return [color2.WHITE, color2.WHITE];
  }

  getDashLineColor() {
    return color2.VERIZONULTRA_MAIN;
  }

  getTelephone() {
    return "";
  }

  getSupportEmail() {
    return "support@aifi.io";
  }

  getAddress() {
    return "";
  }

  getTouLink() {
    return "http://aifi.com";
  }

  getAboutUsLink() {
    return "http://aifi.com";
  }

  getAPIEndpoint() {
    return "https://oasis-api.12-17.client.aifi.io";
  }

  getStripePublicKey() {
    return "";
  }

  getCircleLogo() {
    return <LogoVerizonUltra />;
  }
}

const Singleton = new VerizonUltraFlavor();
export default Singleton;
