import { dpToPx } from "./util";

export const font = {
  size: {
    small: dpToPx(10),
    subtitleText: dpToPx(12),
    receiptCaption: dpToPx(14),
    titleText: dpToPx(16),
    caption: dpToPx(18),
    input: dpToPx(18),
    header: dpToPx(32),
  },
  family: {
    ibmPlexMonoMedium: "IBM Plex Mono",
    ibmPlexMonoBold: "IBM Plex Mono",
    interSemiBold: "Inter",
  },
};
