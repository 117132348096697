import { Flavor } from "./flavor";
import { color2 } from "src/styles";
import { LogoAVFC } from "src/components/LogoAVFC";

class AVFCFlavor extends Flavor {
  getName() {
    return "AVFC";
  }

  getFullName() {
    return "AVFC";
  }

  getMainColor() {
    return color2.ASTON_VILLA;
  }

  getLinearGradientBackground() {
    return [color2.WHITE, color2.ASTON_VILLA];
  }

  getDashLineColor() {
    return color2.ASTON_VILLA;
  }

  getTelephone() {
    return "";
  }

  getSupportEmail() {
    return "holtebar@compass-group.co.uk";
  }

  getAddress() {
    return "";
  }

  getTouLink() {
    return "http://aifi.com";
  }

  getAboutUsLink() {
    return "http://aifi.com";
  }

  getAPIEndpoint() {
    return "https://oasis-api.53-17.client.aifi.io";
  }

  getStripePublicKey() {
    return "";
  }

  getCircleLogo() {
    return <LogoAVFC />;
  }
}

const Singleton = new AVFCFlavor();
export default Singleton;
