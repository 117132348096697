import { Flavor } from "./flavor";
import { color2 } from "src/styles";

class AldiFlavor extends Flavor {
  getCode() {
    return "224-12";
  }

  getName() {
    return "Aldi";
  }

  getFullName() {
    return "Aldi";
  }

  getMainColor() {
    return color2.BLUE_AIFI;
  }

  getLinearGradientBackground() {
    return [color2.WHITE, color2.LIGHT_BLUE];
  }

  getDashLineColor() {
    return color2.LIGHT_BLUE2;
  }

  getAPIEndpoint() {
    return "https://oasis-api.224-12.client.aifi.io";
  }

  getStripePublicKey() {
    return "pk_test_51ILX3HDSugCw1CsrdGMZYeBmb5xxpi9TYqP7srrQ65SeNb8HsGxZwZIcYUCcEN0B7lemHcDjLUXfaq6JShG3pAou007swfkbxy";
  }
}

const Singleton = new AldiFlavor();
export default Singleton;
