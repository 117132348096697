import React, { FC } from "react";
import { Col, Row } from "reactstrap";
import QRCode from "react-qr-code";
import { PoweredByAiFi } from "src/assets/icons/PoweredByAiFi";
import styled from "styled-components";
import { config } from "src/lib/config";
import HeaderLogo from "src/components/HeaderLogo";

const Wrapper = styled(Col)`
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  gap: 2%;
`;

const Poster: FC = () => {
  const webReceiptURL = config.messages.poster.receiptURL;
  const message = config.messages.poster.content.replace(
    "#{receiptURL}",
    webReceiptURL
  );

  return (
    <Row className="justify-content-center" p="xs">
      <Wrapper
        xs={8}
        className="text-center py-1"
      >
        <Row className="h-25 align-items-center">
          <HeaderLogo />
        </Row>
        <Row style={{height: "20%"}}>
          <QRCode value={webReceiptURL} style={{ height: "100%" }} />
        </Row>
        <Row className="h-auto">
          <h5 style={{ whiteSpace: "pre-line" }}>
            {message}
          </h5>
        </Row>
        <Row className="mt-auto align-self-center">
          <PoweredByAiFi />
        </Row>
      </Wrapper>
    </Row>
  );
};

export default Poster;
