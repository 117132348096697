import React from "react";
import { Flavor } from "./flavor";
import { color2 } from "src/styles";
import { LogoMiami } from "src/components/LogoMiami";

class MiamiFlavor extends Flavor {
  getName() {
    return "Miami";
  }

  getFullName() {
    return "Miami";
  }

  getMainColor() {
    return color2.MIAMI_MAIN;
  }

  getLinearGradientBackground() {
    return [color2.WHITE, color2.MIAMI_MAIN];
  }

  getDashLineColor() {
    return color2.MIAMI_MAIN;
  }

  getTelephone() {
    return "(305) 943-8000";
  }

  getSupportEmail() {
    return "support@aifi.io";
  }

  getAddress() {
    return "Miami Dolphins, 347 Don Shula Drive, Miami Gardens, Fla., 33056.";
  }

  getCircleLogo() {
    return <LogoMiami />;
  }

  getTouLink() {
    return "http://aifi.com";
  }

  getAboutUsLink() {
    return "http://aifi.com";
  }

  getAPIEndpoint() {
    return "https://oasis-api.45-17.client.aifi.io";
  }

  getStripePublicKey() {
    return "pk_test_51ILX3HDSugCw1CsrdGMZYeBmb5xxpi9TYqP7srrQ65SeNb8HsGxZwZIcYUCcEN0B7lemHcDjLUXfaq6JShG3pAou007swfkbxy";
  }
}

const Singleton = new MiamiFlavor();
export default Singleton;
