import React from "react";
import { Flavor } from "./flavor";
import { color2 } from "src/styles";
import { LogoRio } from "src/components/LogoRio";

class RioFlavor extends Flavor {
  getName() {
    return "Rio Market";
  }

  getFullName() {
    return "Rio Market";
  }

  getMainColor() {
    return color2.RIO;
  }

  getLinearGradientBackground() {
    return [color2.WHITE, color2.RIO];
  }

  getCircleLogo() {
    return <LogoRio />;
  }

  getAPIEndpoint() {
    return "https://api.rambutan.cloud.aifi.io";
  }

  getStripePublicKey() {
    return "pk_test_51GsPOcHzUK79s8qdrI1F9fnbgBVppY8TjsHjnNbXzDgkhA0PD71EDHKZ6qA2rME62zuJS2g5pbBx54w1Ch1TTaTj00RsMiydjN";
  }
}

const Singleton = new RioFlavor();
export default Singleton;
