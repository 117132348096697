import { LogoPWC } from "src/components/LogoPWC";
import { color2 } from "../../styles";
import { Flavor } from "./flavor";

class PWCFlavor extends Flavor {
  getName() {
    return "Frictionless Futures";
  }

  getFullName() {
    return "Frictionless Futures";
  }

  getMainColor() {
    return color2.PWC;
  }

  getLinearGradientBackground() {
    return [color2.WHITE, color2.PWC];
  }

  getDashLineColor() {
    return color2.PWC;
  }

  getAPIEndpoint() {
    return "";
  }

  getCircleLogo() {
    return <LogoPWC />
  }

  getStripePublicKey() {
    return "";
  }
}

const Singleton = new PWCFlavor();
export default Singleton;
