import React from 'react'
import { Flavor } from './flavor'
import { LogoChoice } from 'src/components/LogoChoice'
import { color2 } from "src/styles";
import { timezoneDate } from '../utils';
import { IFeatureFlags } from '../../../../shared/interface';

class ChoiceFlavor extends Flavor {
  getCode() {
    return '24-17'
  }

  getName() {
    return 'Choice'
  }

  getFullName() {
    return 'Choice'
  }

  getMainColor() {
    return color2.BLACK
  }

  getFeatureFlags(): IFeatureFlags {
    return {
      ...super.getFeatureFlags(),
    }
  }

  getLinearGradientBackground() {
    return [color2.WHITE, color2.BLACK]
  }

  getDashLineColor() {
    return color2.BLACK
  }

  getAPIEndpoint() {
    return 'https://oasis-api.24-17.client.aifi.io'
  }

  getStripePublicKey() {
    return 'pk_test_51ILX3HDSugCw1CsrdGMZYeBmb5xxpi9TYqP7srrQ65SeNb8HsGxZwZIcYUCcEN0B7lemHcDjLUXfaq6JShG3pAou007swfkbxy'
  }

  getCircleLogo() {
    return <LogoChoice />
  }

  formatDateTime(dateTime: string, timezone: string) {
    const date = timezoneDate(dateTime, timezone)
    const formatDate = "MM-DD-YYYY";
    const formatTime = "HH:mm";

    return {
      date: date.format(formatDate),
      time: date.format(formatTime),
    };
  }
}

const Singleton = new ChoiceFlavor()
export default Singleton
