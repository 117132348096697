import React from "react";
import { Row, Spinner } from "reactstrap";
import * as R from "ramda";
import styled from "styled-components";

import { RequestStatus } from "src/lib/api/types";
import { MasterContext } from "src/lib/masterContext";
import { ReceiptsList } from "./ReceiptsList";
import { ReceiptInfo } from "./types";

export interface ReceiptsResultProps {
  receipts?: ReceiptInfo[];
  requestStatus: RequestStatus;
}

const Email = styled.a`
  white-space: nowrap;
`;

export const ReceiptResult: React.FC<ReceiptsResultProps> = ({
  requestStatus,
  receipts,
}) => {
  const { flavor, appConfig } = React.useContext(MasterContext);
  const email = flavor.getSupportEmail();
  const hasDraft = R.any(
    (receipt: ReceiptInfo) => receipt.status === "draft",
    receipts ?? []
  );

  const homeOrderNotReadyMsg = appConfig.messages.homeOrderNotReadyMsg;

  if (requestStatus === "idle") {
    return <></>;
  } else if (requestStatus === "loading") {
    return (
      <Row className="justify-content-center">
        <Spinner />
      </Row>
    );
  } else if (requestStatus === "error") {
    return (
      <span>
        Something went wrong and we couldn't find your receipts. Please contact
        our support <Email href={`mailto:${email}`}>{email}</Email>
      </span>
    );
  } else if (requestStatus === "success") {
    if (!receipts?.length) {
      return (
        <span dangerouslySetInnerHTML={{ __html: homeOrderNotReadyMsg }}></span>
      );
    } else if (receipts && receipts?.length) {
      return (
        <>
          {hasDraft && (
            <p>
              Your receipt is being processed, and it will soon be updated here.
              Please refresh the page shortly.
            </p>
          )}
          <ReceiptsList receipts={receipts} />
        </>
      );
    }
  }

  return <></>;
};
