import React from "react";
import { Flavor } from "./flavor";
import { color2 } from "src/styles";
import { LogoMSFT } from "src/components/LogoMSFT";

class MSFTFlavor extends Flavor {
  getName() {
    return "MSFT";
  }

  getFullName() {
    return "MSFT";
  }

  getMainColor() {
    return color2.MSFT_MAIN;
  }

  getLinearGradientBackground() {
    return [color2.WHITE, color2.MSFT_MAIN];
  }

  getDashLineColor() {
    return color2.MSFT_MAIN;
  }

  getTelephone() {
    return "";
  }

  getSupportEmail() {
    return "support@aifi.io";
  }

  getAddress() {
    return "";
  }

  getTouLink() {
    return "http://aifi.com";
  }

  getCircleLogo() {
    return <LogoMSFT />;
  }

  getAboutUsLink() {
    return "http://aifi.com";
  }

  getAPIEndpoint() {
    return "https://oasis-api.65-17.client.aifi.io";
  }

  getStripePublicKey() {
    return "";
  }
}

const Singleton = new MSFTFlavor();
export default Singleton;
