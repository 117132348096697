import { Flavor } from "./flavor";
import { color2 } from "src/styles";

class ShanghaiFlavor extends Flavor {
  getCode() {
    return "8-20";
  }

  getName() {
    return "Shanghai";
  }

  getFullName() {
    return "Shanghai";
  }

  getMainColor() {
    return color2.BLUE_AIFI;
  }

  getLinearGradientBackground() {
    return [color2.WHITE, color2.LIGHT_BLUE];
  }

  getDashLineColor() {
    return color2.LIGHT_BLUE2;
  }

  getAPIEndpoint() {
    return "https://oasis-api.8-20.aifigo.cn";
  }

  getStripePublicKey() {
    return "pk_test_51ILX3HDSugCw1CsrdGMZYeBmb5xxpi9TYqP7srrQ65SeNb8HsGxZwZIcYUCcEN0B7lemHcDjLUXfaq6JShG3pAou007swfkbxy";
  }
}

const Singleton = new ShanghaiFlavor();
export default Singleton;
