import { Flavor } from "./flavor";
import { color2 } from "src/styles";
import { LogoKansasCityChiefs } from "src/components/LogoKansasCityChiefs";

export class KansasCityChiefs extends Flavor {
  getName() {
    return "KCChiefs";
  }

  getFullName() {
    return "KCChiefs";
  }

  getMainColor() {
    return color2.KANSASCITYCHIEFS_RED;
  }

  getLinearGradientBackground() {
    return [color2.WHITE, color2.KANSASCITYCHIEFS_RED];
  }

  getDashLineColor() {
    return color2.KANSASCITYCHIEFS_RED;
  }

  getTelephone() {
    return "";
  }

  getSupportEmail() {
    return "support@aifi.io";
  }

  getAddress() {
    return "";
  }

  getTouLink() {
    return "http://aifi.com";
  }

  getAboutUsLink() {
    return "http://aifi.com";
  }

  getAPIEndpoint() {
    return "";
  }

  getStripePublicKey() {
    return "";
  }

  getCircleLogo() {
    return <LogoKansasCityChiefs />;
  }
}

const Singleton = new KansasCityChiefs();
export default Singleton;
