import React from 'react'
import { Flavor } from './flavor'
import { LogoMercedesBenzStadium } from 'src/components/LogoMercedesBenzStadium'
import { color2 } from "src/styles";
import { timezoneDate } from '../utils';
import { IFeatureFlags } from '../../../../shared/interface';

class MercedesBenzFlavor extends Flavor {
  getCode() {
    return '74-6'
  }

  getName() {
    return 'Mercedes Benz Stadium'
  }

  getFullName() {
    return 'Mercedes Benz Stadium'
  }

  getMainColor() {
    return color2.MERCEDESBENZ_MAIN
  }

  getFeatureFlags(): IFeatureFlags {
    return {
      ...super.getFeatureFlags(),
    }
  }

  getLinearGradientBackground() {
    return [color2.WHITE, color2.MERCEDESBENZ_MAIN]
  }

  getDashLineColor() {
    return color2.MERCEDESBENZ_MAIN
  }

  getAPIEndpoint() {
    return 'https://oasis-api.74-6.oasis.aifi.com'
  }

  getStripePublicKey() {
    return 'pk_test_51ILX3HDSugCw1CsrdGMZYeBmb5xxpi9TYqP7srrQ65SeNb8HsGxZwZIcYUCcEN0B7lemHcDjLUXfaq6JShG3pAou007swfkbxy'
  }

  getCircleLogo() {
    return <LogoMercedesBenzStadium />
  }

  formatDateTime(dateTime: string, timezone: string) {
    const date = timezoneDate(dateTime, timezone)
    const formatDate = "MM-DD-YYYY";
    const formatTime = "HH:mm";

    return {
      date: date.format(formatDate),
      time: date.format(formatTime),
    };
  }

  getSupportEmail(): string | null {
    return 'mbsmarkets@levyrestaurants.com'
  }
}

const Singleton = new MercedesBenzFlavor()
export default Singleton
