import { ITranslation } from './types'

export const zh: ITranslation = {
  addCardScreen: {
    headerTitle: '添加信用卡',
    stripeSetupIntentError: {
      header: 'Error: $$',
      body: 'Something went wrong. Please try another card. Details: $$',
    },
  },
  button: {
    addCard: '添加信用卡',
    completeRegistration: '完成注册',
    confirm: '确认',
    continue: '继续',
    getStarted: '开始',
    goToPayment: '支付选项',
    login: '登录',
    removeCard: '移除信用卡',
    signup: '注册',
    back: '后退',
    skip: '跳过',
    error: '错误',
    payment: '重试支付',
    ok: '好的',
    yes: '是',
    no: '否',
  },
  contactUsScreen: {
    address: '地址',
    email: '邮箱',
    headerTitle: '联系我们',
    phone: '电话',
    dataProtectionEmail: '删除数据',
    dataProtectionTitle:
      'If you would like to request your personal data to be deleted, please send us an email to: ',
  },
  api: {
    E_FORCED_LOGOUT: '您已经被强制退出登录',
  },
  errors: {
    error: '错误',
    // E_CONFLICT: 'User already exists',
    E_NO_INTERNET: '网络错误',
    E_CUSTOMER_NOT_FOUND: '账号不存在',
    E_CUSTOMER_INVALID_CREDENTIALS: '账号或密码错误',
    E_FORBIDDEN: '拒绝访问',
    E_NOT_FOUND: '记录未找到',
    E_ORDER_NOT_FOUND: '订单不存在',
    E_PAYMENT_ERROR: '支付失败',
    E_STORE_NOT_FOUND: '商店不存在',
    E_UNAUTHORIZED: '未授权',
    E_CUSTOMER_PAYMENT_METHOD_NOT_FOUND: '支付方式不存在',
    E_INVALID_BODY: '请检查提交数据',
    E_ORDER_CUSTOMER_RELATION: '订单不数据此账号',
    E_RESET_CODE_ALREADY_GENERATED: '验证码已经发送，请稍后重试',
    E_RESET_CODE_INVALID: '验证码错误',
    E_RESET_CODE_NOT_FOUND: '验证码未找到或已失效',
    E_SHOPIFY_DUPLICATE_EMAIL: '邮箱已注册',
    E_SHOPIFY_DUPLICATE_PHONE: '手机号已注册',
    E_SHOPIFY_INVALID_ORDER_ID: '订单不存在',
    E_SHOPIFY_CUSTOMER_NOT_FOUND: '用户不存在',
    E_CUSTOMER_NO_PAYMENT_METHOD: '未设置支付方式',
    E_CUSTOMER_UNSETTLED_TRANSACTIONS: '您的上一笔交易失败，请联系我们',
    E_CUSTOMER_CARD_EXPIRED: '信用卡已过期',
    SOMETHING_WRONG: '请重试',
  },
  forgotPasswordScreen: {
    title: '忘记密码?',
    wrongCodeErrorMessage: '验证码错误',
    wrongEmailErrorMessage: '邮箱错误',
    codeErrorMessage: '验证码不能为空',
    emailErrorMessage: '邮箱不能为空',
    passwordErrorMessage: '密码不能为空',
    shortPasswordErrorMessage: '密码至少8位',
    alreadyHaveCode: '验证码已发送',
  },
  form: {
    email: '邮箱地址',
    emailPlaceholder: 'email@example.com',
    firstName: '名',
    firstNamePlaceholder: '名',
    lastName: '姓',
    lastNamePlaceholder: '姓',
    newPassword: '新密码',
    password: '密码',
    passwordPlaceholder: '至少8位密码',
    resetCode: '重置验证码',
    resetCodePlaceholder: '12345',
    productName: '商品名',
    productQuantity: '数量',
    labelPasswordShow: '显示',
    labelPasswordHide: '隐藏',
    taxId: 'tax id (选填)',
    taxIdPlaceholder: '1234567890',
    companyName: '公司 (选填)',
    companyNamePlaceholder: '公司',
    phone: '手机号',
    phonePlaceholder: '13888888888',
    address: '地址',
    addressPlaceholder: '上海市虹口区',
    addressError: '地址不能为空',
    firstNameErrorMessage: '字段不能为空',
    lastNameErrorMessage: '字段不能为空',
    emailErrorMessage: '邮箱不能为空',
    invalidEmailErrorMessage: '邮箱无效',
    passwordErrorMessage: '密码不能为空',
    shortPasswordErrorMessage: '密码必须大于8位',
    taxIdErrorMessage: '字段错误',
    taxIdEmptyErrorMessage: '字段不能为空',
    companyNameEmptyErrorMessage: '字段不能为空',
    phoneEmptyErrorMessage: '手机号不能为空',
    phoneInvalidErrorMessage: '手机号格式错误',
  },
  loginScreen: {
    forgotPassword: '忘记密码?',
    subtitle: '使用账号和密码登录',
    title: '欢迎回来',
  },
  homeScreen: {
    cardInvalidSubtitle: '请先添加信用卡',
    cardValidTitle: '扫码进入',
    partyText: '本次购物人数',
    greeting: '你好',
    errorUnpaid: '请先支付所有订单',
    errorUnpaidButton: '检查未支付订单',
    errorExpired: '信用卡已过期，请添加有效信用卡',
    errorExpiredButton: '添加信用卡',
    errorNoCard: '未添加信用卡，请先添加信用卡',
    errorNoCardButton: '添加信用卡',
    errorUnknown: '未知错误',
  },
  menu: {
    access: '进门',
    payment: '支付',
    receipts: '订单',
    settings: '设置',
  },
  onboardingScreen: {
    subtitle1: '使用二维码扫码进门...',
    title1: '扫码',
    subtitle2: '让他们使用您的二维码扫码，然后购物',
    title2: '多人购物?',
    subtitle3: '您购买的东西都会自动添加到购物车',
    title3: '买买买！',
    subtitle4: '完成购物购，直接离开即可，您会自动收到订单',
    title4: '准备好了吗? 开始',
  },
  registerLoginScreen: {
    register: '注册',
    login: '登录',
  },
  paymentScreen: {
    card: {
      subtitle: '这是您当前的支付方式.',
    },
    endsWith: '* * * * $$',
    noCard: {
      subtitle: '您尚未添加支付方式. 点击按钮添加',
    },
    removeCard: {
      cancel: '取消',
      delete: '删除',
      subtitle: '确定移除信用卡?',
      title: '移除信用卡',
    },
    title: '支付方式',
    addCreditCard: {
      cardView: {
        labels: {
          expiry: 'MONTH/YEAR',
        },
      },
      labels: {
        name: '姓名',
        number: '卡号',
        expiry: '过期时间',
        cvc: 'CVC/CCV',
        postalCode: 'POSTAL CODE',
      },
      placeholders: {
        name: '姓名',
        number: '1234 5678 1234 5678',
        expiry: 'MM/YY',
        cvc: 'CVC',
        postalCode: '34567',
      },
    },
  },
  receiptDetailScreen: {
    headerTitle: '订单详情',
    quantity: '数量: $$',
    subtotal: 'Subtotal',
    support: {
      body: '输入详情...\n\n---\n订单号: $$',
      contactCta: '如果我错了请修正我',
      subject: '请求帮助',
    },
    tax: '税',
    taxCRV: 'CRV',
    total: '总量',
    title: '订单',
    processTime: '处理时长',
    shoppingTime: '购物时长',
    emptyMessage: '谢谢您的本次购物, 本次未购买任何商品. 期待下次光临!',
    problem: '订单有问题? 请将修正后的订单发送给我们重新审核',
    date: '日期',
    address: '谢谢您的本次购物.',
    noItems: '未购物',
    paymentStatus: '支付状态',
    draftProducts: '系统处理您的购物, 很快您将收到订单.',
    retryPaymentSuccessInfo: '付款成功，谢谢！',
    retryPaymentFailureInfo: '付款失败，请稍后重试。',
    orderId: '订单号',
    feedbackSuccessTip: '感谢您的反馈！',
    remindLaterTip: '好的！我们会在您方便的时间再次联系您。',
    feedbackModalTitle: '您有几分意愿向朋友推荐我们的购物之旅？',
    feedbackModalTip: '请在 0 - 10 的范围内打分',
    submitFeedback: '提交反馈',
    remindLater: '稍后提醒我',
    dontAskAgain: '不再询问',
    notLikely: '不喜欢',
    veryLikely: '非常喜欢',
    listOfProducts: '產品'
  },
  receiptDetailComplain: {
    title: '用户反馈',
    send: '发送',
    feedbackTitle: '返回',
    feedbackMessage: '感谢反馈正确订单，我们会尽快处理',
    addItem: '添加商品到订单',
    reportProblem: '报告错误',
    submitReview: '申请审核',
    confirmTitle: '订单已发送',
    confirmMessage:
      '您的收据已成功提交。 我们将在接下来的5个工作日内与您联系。 我们不会对原始订单进行扣款。 感谢您对我们的帮助',
    confirmButton: '完成',
    addMissingItem: '添加缺失商品',
    addMissingItemMessage: '添加备注 (选填)',
  },
  receiptAddItem: {
    title: '添加商品',
  },
  receiptsScreen: {
    receipts: '订单',
    detail: '$$ 商品',
    detailItems: {
      one: '商品',
      other: '商品',
    },
    headerTitle: '订单',
    noReceiptsMessage: '暂无订单',
    processing1: '处理中',
    processing2: '处理中',
    label: {
      contested: '审核中',
      draft: '草稿',
      empty: '空',
      paid: '已支付',
      pending: '草稿',
      refunded: '已退款',
      reviewed: '已审核',
      unknown: '未知',
      unpaid: '未支付',
    },
    message: {
      contested: '争议消息',
      draft: '草稿消息',
      empty: '空消息',
      paid: '已支付',
      pending: '处理中消息',
      refunded: '已退款消息',
      reviewd: '已审核消息',
      unpaid: '未支付消息',
    },
    resetAll: '重置全部',
    for: 'for',
    minutes: '分钟',
    hours: '小时',
    days: '天',
    stillWorkingOnIt: '还在处理中!',
  },
  settingsScreen: {
    headerTitle: '设置',
    items: {
      about: '关于',
      contact: '联系我们',
      faq: 'FAQ',
      faq2: '系统是如何工作的?',
      logout: '登出',
      reportProblem: '报告问题',
      termsOfUse: '使用条款',
      storybook: '故事书',
      disableNotification: '禁用通知',
    },
    security: '安全',
    aboutUs: '关于我们',
    myCards: '我的信用卡',
    more: '更多',
    developer: '开发者',
    taxId: 'Tax ID:',
    companyName: '公司:',
    address: '地址:',
  },
  signupScreen: {
    termsPrompt: '请先同意我们的 ',
    termsPromptLink: '使用条款',
    title: '注册',
    privacyPolicy: '隐私策略',
    privacyPolicyPrefix: '并阅读',
  },
  slideModal: {
    close: '关闭',
    searchPlaceholder: '搜索关键词',
  },
  notificationPermission: {
    title: '给您发送通知',
    text: '我愿意从接收关于自动商店的推送消息',
  },
}
