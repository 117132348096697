import React from "react";
import {
  Route,
  Routes as BrowserRoutes,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { ApiProvider } from "src/lib/ApiProvider";
import { ApplePayInstruction , GooglePayInstructions } from "./Instructions";
import Poster from "./Poster";

import { Receipt } from "./Receipt/Receipt";
import { Receipts } from "./Receipts";

export const AppRoutes: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const sessionToken = searchParams.get("sessionToken");
  const orderId = searchParams.get("orderId");

  /**
   * We need to handle old routing when the session token was passed as a
   * query parmaters ?sessionToken=''&orderId=''
   */
  React.useEffect(() => {
    if (sessionToken && orderId) {
      navigate(`/receipts/${orderId}/${sessionToken}`);
    }
  }, [navigate, orderId, sessionToken]);

  return (
    <BrowserRoutes>
      <Route
        path="/"
        element={
          <ApiProvider>
            <Receipts />
          </ApiProvider>
        }
      />
      <Route
        path="/receipts/:orderId/:sessionToken"
        element={
          <ApiProvider>
            <Receipt />
          </ApiProvider>
        }
      />
      <Route
        path="/card-instruction-apple"
        element={<ApplePayInstruction />}
      ></Route>
      <Route
        path="/card-instruction-google"
        element={<GooglePayInstructions />}
      ></Route>
      <Route
        path="/poster"
        element={<Poster />}
      ></Route>
    </BrowserRoutes>
  );
};
