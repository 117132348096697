import { inject, observer } from "mobx-react";
import React, { useContext } from "react";
import { ScrollView, View } from "react-native";
import { NavigationScreenProps } from "react-navigation";
import ProductSearchModal from "src/components/v21/ProductSearchModal";
import { useApi } from "src/lib/ApiProvider";
import { config } from "src/lib/config";
import { MasterContext } from "src/lib/masterContext";

import { BackButton } from "../../../components/BackButton";
import CenterActivityIndicator from "../../../components/CenterActivityIndicator";
import StyledScreen from "../../../components/StyledScreen";
import { Button } from "../../../components/v2/Button";
import { QuantityButton } from "../../../components/v2/QuantityButton";
import ReceiptDetailSection from "../../../components/v2/ReceiptsDetailSection";
import { Input } from "../../../components/v2/TextInput";
import { Store } from "../../../lib/data/store";
import { translate } from "../../../lib/i18n";
import { getTimeSpent } from "../../../lib/utils";
import ReceiptFooter from "../ReceiptDetail/ReceiptFooter";
import ReceiptHeader from "../ReceiptDetail/ReceiptHeader";
import { styles } from "../ReceiptDetail/styles";
import { styles as styles2 } from "./styles";

const CONTEST_FEEDBACK_MAX_LEN = 140;

type ProductListItem = Omit<ApiLineItem, "status" | "totalPrice">;

interface IReceiptDetailComplain extends NavigationScreenProps {
  store: Store;
  order: ApiOrderDetail;
  onBack: () => void;
  onContestSuccess: () => void;
}
const ReceiptDetailComplain: React.FC<IReceiptDetailComplain> = ({
  navigation,
  store,
  order,
  onBack,
  onContestSuccess,
}) => {
  const { authApi } = useApi();
  const { flavor, featureFlags: { hideProcessTime }, appConfig: {timezone} } = useContext(MasterContext);
  const [showSearchProduct, setShowSearchProduct] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [items, setItems] = React.useState<ProductListItem[]>([]);
  const [customerMessage, setCustomerMessage] = React.useState("");
  const [itemDelta, setItemDelta] = React.useState<
    Record<string, ProductListItem>
  >({});
  const [isFormChanged, setIsFormChanged] = React.useState(false);
  const created = flavor.formatDateTime(order.draftCreatedAt, timezone);
  const processTime = order
    ? getTimeSpent(order.draftCreatedAt, order.processedAt)
    : "";

  const handleItemQuantityChange = React.useCallback(
    (item: ApiLineItem) => (quantity: number) => {
      setItemDelta({
        ...itemDelta,
        [item.id]: {
          ...item,
          quantity,
        },
      });
    },
    [itemDelta]
  );

  const handleItemAdd = React.useCallback(
    (product: ApiProductsDetail) => {
      if (!itemDelta[product.id]) {
        setItemDelta({
          ...itemDelta,
          [product.id]: {
            id: product.id,
            image: product.thumbnail,
            name: product.name,
            rin: product.rin,
            quantity: 1,
          },
        });
      } else {
        setItemDelta({
          ...itemDelta,
          [product.id]: {
            ...itemDelta[product.id],
            quantity: itemDelta[product.id].quantity + 1,
          },
        });
      }
      setShowSearchProduct(false);
    },
    [itemDelta]
  );

  React.useEffect(() => {
    const itemsChanged = order.lineItems.map((item) => ({
      ...item,
      quantity: itemDelta[item.id]
        ? itemDelta[item.id].quantity
        : item.quantity,
    }));

    const itemsAdded = Object.entries(itemDelta)
      .filter(([id]) => !order.lineItems.find((x) => id === x.id))
      .map(([, item]) => item);

    setItems([...itemsChanged, ...itemsAdded]);
  }, [itemDelta, order.lineItems]);

  React.useEffect(() => {
    const hasQuantityChanged =
      order.lineItems.some(
        (item) =>
          itemDelta[item.id] && item.quantity !== itemDelta[item.id].quantity
      ) ||
      Object.keys(itemDelta).filter(
        (id) => !order.lineItems.some((x) => x.id === id)
      ).length > 0;

    setIsFormChanged(!!customerMessage || hasQuantityChanged);
  }, [customerMessage, itemDelta, order.lineItems]);

  const handleSubmitReview = React.useCallback(() => {
    setIsLoading(true);
    const contestedReceiptData = {
      orderId: order.graphQlId,
      originalReceipt: order.lineItems.map((i) => ({
        rin: i.rin,
        name: i.name,
        quantity: i.quantity,
      })),
      contestedReceipt: Object.values(itemDelta).map((item) => ({
        rin: item.rin,
        name: item.name,
        quantity: item.quantity,
      })),
      customerMessage,
    };

    authApi
      .post(`api/customer/v2/orders/contest-receipt`, contestedReceiptData)
      .then((response) => {
        setIsLoading(false);
        onContestSuccess();
      })
      .catch((error) => {
        console.error("Error while submitting contest data", error);
        setIsLoading(false);
      });
  }, [
    authApi,
    customerMessage,
    itemDelta,
    onContestSuccess,
    order.graphQlId,
    order.lineItems,
  ]);

  const dateAndTimeData = [
    {
      title: translate("receiptDetailScreen.date"),
      value: `${created.date} ${created.time}`,
    }
  ]

  if (!hideProcessTime) {
    dateAndTimeData.push({
      title: translate("receiptDetailScreen.processTime"),
      value: processTime,
    });
  }

  return order ? (
    <StyledScreen>
      <ScrollView style={styles.scroll}>
        <div
          onClick={onBack}
          style={{
            position: "absolute",
            top: "1rem",
            left: "0.5rem",
            zIndex: 1,
          }}
        >
          <BackButton />
        </div>
        <ReceiptHeader order={order} />
        <View style={styles2.itemsWrapper}>
          {items.map((product) => {
            return (
              <QuantityButton
                key={product.id}
                trailingText={product.name}
                quantity={product.quantity}
                onChange={handleItemQuantityChange(product)}
              />
            );
          })}
        </View>
        <Button
          style={styles.submitButton}
          title={translate("receiptDetailComplain.addMissingItem")}
          onPress={() => {
            setShowSearchProduct(true);
          }}
        />
        <View style={styles2.notesWrapper}>
          <Input
            placeholder={translate(
              "receiptDetailComplain.addMissingItemMessage"
            )}
            style={styles2.notes}
            multiline={true}
            onChangeText={(value) => {
              setCustomerMessage(value);
            }}
            value={customerMessage}
            maxLength={CONTEST_FEEDBACK_MAX_LEN}
          />
        </View>
        <ReceiptDetailSection
          name="date"
          data={dateAndTimeData}
        />
        <ReceiptFooter
          orderId={order.id}
          loading={isLoading}
          submitText={translate("receiptDetailComplain.submitReview")}
          showSubmit={true}
          onSubmit={handleSubmitReview}
          footerText={config.messages.footerPaid}
          isSubmitDisabled={!isFormChanged}
        />
      </ScrollView>
      {showSearchProduct && (
        <ProductSearchModal
          navigation={navigation}
          onDismiss={() => setShowSearchProduct(false)}
          onAdd={handleItemAdd}
        />
      )}
    </StyledScreen>
  ) : (
    <CenterActivityIndicator />
  );
};

export default inject("store")(observer(ReceiptDetailComplain));
