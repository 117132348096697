import { Dimensions, Platform } from "react-native";

const IPHONE_6 = 375;

export const dpToPx = (dp: number | string) => {
  const { width: windowWidth } = Dimensions.get("window");
  return (windowWidth / IPHONE_6) * Number(dp);
};

export const isIphoneX = () => {
  const dimen = Dimensions.get("window");
  return (
    Platform.OS === "ios" &&
    !Platform.isPad &&
    !Platform.isTVOS &&
    (dimen.height === 812 ||
      dimen.width === 812 ||
      dimen.height === 896 ||
      dimen.width === 896)
  );
};
export const getBottomSpace = () => {
  return isIphoneX() ? 34 : 0;
};
