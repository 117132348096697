import React from "react";
import { Col, Row } from "reactstrap";

export const InstructionStep = ({
  header,
  content,
  image,
}: {
  header: string;
  content: string | JSX.Element;
  image?: string;
}) => (
  <Row className="justify-content-center" p="sm">
    <Col md={4} sm={4}></Col>
    <Col md={4} sm={4}>
      <h4>{header}</h4>
      <p>{content}</p>
      {image && <img style={{ width: "100%" }} src={image} alt="" />}
    </Col>
    <Col md={4} sm={4} />
  </Row>
);
