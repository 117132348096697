import React, { PropsWithChildren, useContext } from "react";
import { MasterContext } from "src/lib/masterContext";
import styled from "styled-components";

export interface FlavouredStylesProps {
  mainColor: string;
  dashlineColor: string;
}

export const FlavorStylesWrapper = styled.div<FlavouredStylesProps>`
  .btn-secondary {
    background: ${(props) => props.mainColor};
    border-color: ${(props) => props.mainColor};
  }

  a {
    color: ${(props) => props.mainColor};
  }
`;

export const FlavorStyles: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const { flavor } = useContext(MasterContext);

  return (
    <FlavorStylesWrapper
      mainColor={flavor.getMainColor()}
      dashlineColor={flavor.getDashLineColor()}
    >
      {children}
    </FlavorStylesWrapper>
  );
};
