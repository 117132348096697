import { FlexAlignType, StyleSheet } from "react-native";
export const cssStyles = {
  wrapper: {
    width: "100%",
  },
  logoWrapper: {
    alignItems: "center" as FlexAlignType,
    padding: "5vh",
    maxHeight: '33vh'
  },
}

export const styles = () =>
  StyleSheet.create(cssStyles);
