import React from "react";
import card from "src/assets/images/instructions/google/card.jpeg";


import styled from "styled-components";
import { InstructionStep } from "./InstructionStep";

const InstructionWrapper = styled.div`
  padding: 10px 0;
  h4 {
    margin-top: 10px;
  }
  p {
    margin: 10px 0;
  }
`;

const GOOGLE_PAY_INSTRUCTIONS_STEPS = [
  {
    key: "step1",
    content:
      'Open Google Wallet and check the last 4 digits of your card.',
    image: card,
  }
];

export const GooglePayInstructions: React.FC = () => {
  return (
    <InstructionWrapper>
      {GOOGLE_PAY_INSTRUCTIONS_STEPS.map(({ content, image, key }, index) => (
        <InstructionStep
          content={content}
          image={image}
          key={key}
          header={`Step ${index + 1}`}
        />
      ))}
      <InstructionStep
        content={
          <>
            In case of any problems please refer to{" "}
            <a href="https://support.google.com/wallet/answer/12059601?hl=en&ref_topic=11925503">
              instructions
            </a>{" "}
            provided by Google
          </>
        }
        header="Got a problem?"
      />
    </InstructionWrapper>
  );
};
