import { StyleSheet } from "react-native";
import { color2, dpToPx } from "../../../styles";
import { font } from "../../../styles/themes";

export const styles = StyleSheet.create({
  problem: {
    marginTop: 20,
    marginLeft: 20,
    marginRight: 20,
    fontSize: 14,
    lineHeight: 25,
    fontFamily: font.family.interSemiBold,
    textAlign: "center",
    color: color2.GRAY4,
  },
  notesWrapper: {
    marginTop: 20,
    marginBottom: 10,
  },
  notes: {
    borderColor: "#ced4da",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 5,
    padding: 10,
    minHeight: 80,
    margin: 10,
  },
  itemsWrapper: {
    padding: dpToPx(8),
  },
});
