import React from "react";

export const Search: React.FC<MenuIconProps> = ({ color, testId: testID }) => (
  <svg width="13" height="15" testid={testID}>
    <g fill="none" fillRule="evenodd">
      <path d="M-6-4.5h24v24H-6z" />
      <path
        d="M12.76 13.065l-2.488-2.542a6.088 6.088 0 001.504-4.007C11.776 3.2 9.134.5 5.888.5S0 3.2 0 6.516c0 3.318 2.642 6.017 5.888 6.017 1.152 0 2.22-.345 3.119-.919l2.585 2.642a.81.81 0 00.577.244c.21 0 .421-.086.576-.244a.84.84 0 00.014-1.191zM1.643 6.516c0-2.384 1.897-4.336 4.243-4.336 2.348 0 4.23 1.952 4.23 4.336 0 2.384-1.897 4.337-4.229 4.337-2.333 0-4.244-1.939-4.244-4.337z"
        fill={color}
      />
    </g>
  </svg>
);
