import { ITranslation } from './types'

export const de: ITranslation = {
  addCardScreen: {
    headerTitle: 'Karte hinzufügen',
    stripeSetupIntentError: {
      header: 'Fehler: $$',
      body: 'Something went wrong. Please try another card. Details: $$',
    },
  },
  button: {
    addCard: 'Karte hinzufügen',
    completeRegistration: 'Registrierung abschließen',
    confirm: 'Bestätigen',
    continue: 'Fortfahren',
    getStarted: 'Start',
    goToPayment: 'Zahlungsoptionen',
    login: 'Anmelden',
    removeCard: 'Karte entfernen',
    signup: 'Registrieren',
    back: 'Zurück',
    skip: 'Überspringen',
    error: 'Fehler',
    payment: 'Zahlung wiederholen',
    ok: 'Ok',
    yes: 'Ja',
    no: 'Nein',
  },
  contactUsScreen: {
    address: 'Adresse',
    email: 'E-Mail',
    headerTitle: 'Kontaktieren Sie uns',
    phone: 'Telefon',
    dataProtectionEmail: 'Ich möchte mein Benutzerkonto löschen',
    dataProtectionTitle:
      'Wenn Sie Ihre personenbezogenen Daten löschen lassen möchten, senden Sie uns bitte eine E-Mail an: ',
  },
  api: {
    E_FORCED_LOGOUT: 'Sie wurden abgemeldet, da Ihre Verbindung abgelaufen ist.',
  },
  errors: {
    error: 'Fehler',
    // E_CONFLICT: 'Benutzer existiert bereits',
    E_NO_INTERNET: 'Keine Internetverbindung',
    E_CUSTOMER_NOT_FOUND: 'Kunde wurde nicht gefunden',
    E_CUSTOMER_INVALID_CREDENTIALS: 'Ungültige E-Mail oder Passwort',
    E_FORBIDDEN: 'Zugang verboten',
    E_NOT_FOUND: 'Eintrag nicht gefunden',
    E_ORDER_NOT_FOUND: 'Auftrag existiert nicht',
    E_PAYMENT_ERROR:
      'Bei der Kontaktaufnahme mit dem Zahlungsdienstleister ist etwas schief gelaufen',
    E_STORE_NOT_FOUND: 'Shop nicht gefunden',
    E_UNAUTHORIZED: 'Zugriff nicht autorisiert',
    E_CUSTOMER_PAYMENT_METHOD_NOT_FOUND: 'Kunde hat keine Zahlungsmethode eingerichtet',
    E_INVALID_BODY: 'Bitte füllen Sie alle Felder aus oder die Felder haben ein ungültiges Format',
    E_ORDER_CUSTOMER_RELATION: 'Auftrag gehört nicht zum Kunden',
    E_RESET_CODE_ALREADY_GENERATED:
      'Der Reset-Code für diese E-Mail-Adresse wurde bereits gesendet. Versuchen Sie es später erneut.',
    E_RESET_CODE_INVALID: 'Reset-Code ist ungültig',
    E_RESET_CODE_NOT_FOUND: 'Reset-Token nicht gefunden oder abgelaufen',
    E_SHOPIFY_DUPLICATE_EMAIL: 'E-Mail ist bereits vergeben',
    E_SHOPIFY_DUPLICATE_PHONE: 'Telefonnummer ist bereits vergeben',
    E_SHOPIFY_INVALID_ORDER_ID: 'Auftrag existiert nicht',
    E_SHOPIFY_CUSTOMER_NOT_FOUND: 'Kunde nicht gefunden',
    E_CUSTOMER_NO_PAYMENT_METHOD: 'Kunde hat keine Zahlungsmethode eingerichtet',
    E_CUSTOMER_UNSETTLED_TRANSACTIONS:
      'Ihre vorherige Transaktion ist fehlgeschlagen. Kontaktieren Sie unseren Support.',
    E_CUSTOMER_CARD_EXPIRED: 'Die Karte des Kunden ist abgelaufen',
    SOMETHING_WRONG: 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.',
  },
  forgotPasswordScreen: {
    title: 'Passwort vergessen?',
    wrongCodeErrorMessage: 'Falscher Code',
    wrongEmailErrorMessage: 'Falsche E-Mail-Adresse',
    codeErrorMessage: 'Das Feld darf nicht leer sein',
    emailErrorMessage: 'Bitte geben Sie eine E-Mail-Adresse an.',
    passwordErrorMessage: 'Bitte geben Sie ein Passwort ein.',
    shortPasswordErrorMessage: 'Die Passwortlänge muss mindestens 8 Zeichen lang sein',
    alreadyHaveCode: 'Sie besitzen bereits einen Code',
  },
  form: {
    email: 'E-Mail-Adresse',
    emailPlaceholder: 'max@mustermann.de',
    firstName: 'Vorname',
    firstNamePlaceholder: 'Max',
    lastName: 'Nachname',
    lastNamePlaceholder: 'Mustermann',
    newPassword: 'Neues Passwort',
    password: 'Passwort',
    passwordPlaceholder: '8+ Zeichen',
    resetCode: 'Code zurücksetzen',
    resetCodePlaceholder: '12345',
    productName: 'Produktname',
    productQuantity: 'Menge',
    labelPasswordShow: 'Anzeigen',
    labelPasswordHide: 'Ausblenden',
    phone: 'Phone',
    phonePlaceholder: '13888888888',
    taxId: 'Steueridentifikationsnummer (optional)',
    taxIdPlaceholder: '1234567890',
    companyName: 'Firmenname (optional)',
    companyNamePlaceholder: 'Musterfirma GmbH',
    address: 'Adresse',
    addressPlaceholder: 'Musterstrasse 1, 12345 Musterstadt',
    addressError: 'Bitte geben Sie eine Adresse an.',
    firstNameErrorMessage: 'Bitte geben Sie Ihren Vorname an.',
    lastNameErrorMessage: 'Bitte geben Sie Ihren Nachname an.',
    emailErrorMessage: 'Bitte geben Sie eine E-Mail-Adresse an.',
    invalidEmailErrorMessage: 'Dies ist keine gültige E-Mail',
    passwordErrorMessage: 'Bitte geben Sie eine Passwort ein.',
    shortPasswordErrorMessage: 'Die Passwortlänge muss mindestens 8 Zeichen lang sein.',
    taxIdErrorMessage: 'Dies ist keine gültige Steuer-ID.',
    taxIdEmptyErrorMessage: 'Bitte geben Sie eine Steuer-ID an.',
    companyNameEmptyErrorMessage: 'Der Firmenname darf nicht leer sein',
    phoneEmptyErrorMessage: 'Phone is not allowed to be empty',
    phoneInvalidErrorMessage: 'A phone number should be 8-15 digits(include country code)',
  },
  loginScreen: {
    forgotPassword: 'Passwort vergessen?',
    subtitle: 'Melden Sie sich mit Ihrer E-Mail-Adresse und dem Passwort an',
    title: 'Willkommen zurück',
  },
  homeScreen: {
    cardInvalidSubtitle: 'Bitte schließen Sie die Einrichtung Ihres Kontos ab.',
    cardValidTitle: 'QR-Code scannen',
    partyText: 'Anzahl der Personen in Ihrer Gruppe:',
    greeting: 'Willkommen',
    errorUnpaid: 'Ihr Zugang wurde verwehrt. Bitte bezahlen Sie all Ihre Belege.',
    errorUnpaidButton: 'Überprüfung der unbezahlten Belege',
    errorExpired: 'Ihre Karte ist ungültig. Bitte fügen Sie eine neue Karte hinzu.',
    errorExpiredButton: 'Karte hinzufügen',
    errorNoCard: 'Es wurde keine Karte zu Ihrem Konto hinzugefügt.',
    errorNoCardButton: 'Karte hinzufügen',
    errorUnknown: 'Unbekannter Fehler',
  },
  menu: {
    access: 'Zugang',
    payment: 'Zahlung',
    receipts: 'Belege',
    settings: 'Kontakt',
  },
  onboardingScreen: {
    subtitle1: 'Betreten Sie den Store, indem Sie Ihren QR-Code in der App verwenden.',
    title1: 'Scannen zum Betreten des Shops',
    subtitle2:
      'Scannen Sie Ihren QR-Code und lassen Sie Ihre Begleitpersonen zuerst den Laden betreten.',
    title2: 'Mehrere Personen?',
    subtitle3: ' Für Ihren Einkauf wird automatisch ein virtueller Warenkorb angelegt.',
    title3: 'Einkaufen',
    subtitle4: 'Nach dem Verlassen erhalten Sie Ihren Kassenbon.',
    title4: 'Ready? Just Go',
  },
  registerLoginScreen: {
    register: 'Registrieren',
    login: 'Anmelden',
  },
  paymentScreen: {
    card: {
      subtitle: 'Dies ist Ihre aktuell hinterlegte Zahlungsmethode.',
    },
    endsWith: '* * * * $$',
    noCard: {
      subtitle: 'Sie haben derzeit keine Zahlungsmethode eingerichtet. Fügen Sie eine Karte hinzu.',
    },
    removeCard: {
      cancel: 'Abbrechen',
      delete: 'Löschen',
      subtitle: 'Sind Sie sicher, dass Sie Ihre Karte entfernen möchten?',
      title: 'Karte entfernen',
    },
    title: 'Zahlungsmethode',
    addCreditCard: {
      cardView: {
        labels: {
          expiry: 'Monat/Jahr',
        },
      },
      labels: {
        name: 'Name des Karteninhabers',
        number: 'Kartennummer',
        expiry: 'Ablaufdatum',
        cvc: 'CVC/CCV',
        postalCode: 'Postleitzahl',
      },
      placeholders: {
        name: 'Vollständiger Name',
        number: '1234 5678 1234 5678',
        expiry: 'MM/YY',
        cvc: 'CVC',
        postalCode: '34567',
      },
    },
  },
  receiptDetailScreen: {
    headerTitle: 'Beleg Details',
    quantity: 'Menge: $$',
    subtotal: 'Nettosumme:',
    support: {
      body: 'Details eingeben...\n\n---\nTransaktions-ID: $$',
      contactCta: 'Korrigieren Sie mich, wenn ich falsch liege',
      subject: 'Supportanfrage',
    },
    taxBreakdown: {
      headers: {
        rate: "Steuer %",
        netto: "Netto",
        brutto: "Brutto",
        tax: "Steuer",
        total: "Gesamtbetrag:"
      }
    },
    tax: 'Steuer',
    taxCRV: 'CRV',
    total: 'Zu Zahlen:',
    title: 'Beleg',
    processTime: 'Bearbeitungszeit',
    shoppingTime: 'Einkaufszeit',
    emptyMessage:
      'Diesmal werden Ihnen keine Kosten in Rechnung gestellt. Wir freuen uns auf Ihren nächsten Besuch!',
    problem:
      'Sie haben ein Problem gefunden? Bitte senden Sie uns den bearbeiteten Beleg und wir werden uns um das Problem kümmern.',
    date: 'Daten',
    address: 'Danke für Ihren Einkauf.',
    noItems: 'Keine Artikel genommen',
    listOfProducts: 'Artikelauflistung:',
    paymentStatus: 'Zahlungsstatus:',
    draftProducts: 'Wir bearbeiten Ihren Einkauf. Sie erhalten in Kürze Ihren Beleg.',
    orderId: 'Bon-Nummer:',
    retryPaymentSuccessInfo: 'Vielen Dank!',
    retryPaymentFailureInfo:
      'Die Zahlung war nicht erfolgreich, bitte versuchen Sie es später erneut.',
    feedbackSuccessTip: 'Thanks for sharing your feedback!',
    remindLaterTip: 'Great! We will reach out at a better time.',
    feedbackModalTitle: 'How likely would you recommend this shopping experience to your friend?',
    feedbackModalTip: 'Please rate on scale from 0 - 10',
    submitFeedback: 'Submit Feedback',
    remindLater: 'Remind me later',
    dontAskAgain: 'Don’t ask again',
    notLikely: 'Not Likely',
    veryLikely: 'Very Likely',
  },
  receiptDetailComplain: {
    title: 'Benutzer-Feedback',
    send: 'Senden',
    feedbackTitle: 'Rückmeldung',
    feedbackMessage:
      'Vielen Dank, dass Sie Ihren Beleg eingereicht haben! Wir werden uns so schnell wie möglich darum kümmern.',
    addItem: 'Produkt beim Beleg hinzufügen',
    reportProblem: 'Problem melden',
    submitReview: 'Zur Überprüfung einsenden',
    confirmTitle: 'Beleg gesendet',
    confirmMessage:
      'Vielen Dank für Ihre Kooperation, wir werden uns so schnell wie möglich bei Ihnen melden. Dies dauert in der Regel zwischen 2-5 Arbeitstagen.',
    confirmButton: 'Fertig',
    addMissingItem: 'Produkt hinzufügen',
    addMissingItemMessage: 'Eine Nachricht hinzufügen (optional)',
  },
  receiptAddItem: {
    title: 'Produkt hinzufügen',
  },
  receiptsScreen: {
    receipts: 'Belege',
    detail: '$$ Artikel',
    detailItems: {
      one: 'Artikel',
      other: 'Artikel',
    },
    headerTitle: 'Belege',
    noReceiptsMessage: 'Noch keine Belege vorhanden.',
    processing1: 'Bearbeitung',
    processing2: 'Transaktion',
    label: {
      contested: 'in Überprüfung',
      draft: 'Entwurf',
      empty: 'Leer',
      paid: 'Bezahlt',
      pending: 'Ausstehend',
      refunded: 'Rückerstattet',
      reviewed: 'Überarbeitet',
      unknown: 'Unbekannt',
      unpaid: 'Unbezahlt',
    },
    message: {
      contested: 'in Überprüfung Nachricht',
      draft: 'Entwurf der Nachricht',
      empty: 'Leere Nachricht',
      paid: 'Bezahlte Nachricht',
      pending: 'Ausstehende Nachricht',
      refunded: 'Rückerstattungsnachricht',
      reviewd: 'Überprüfte Nachricht',
      unpaid: 'Unbezahlte Nachricht',
    },
    resetAll: 'Alles zurücksetzen',
    for: 'für',
    minutes: 'Minuten',
    hours: 'Std',
    days: 'Tage',
    stillWorkingOnIt: 'Wir arbeiten noch daran!',
  },
  settingsScreen: {
    headerTitle: 'Einstellungen',
    items: {
      about: 'Impressum',
      contact: 'Kontaktieren Sie uns',
      faq: 'FAQ',
      faq2: 'Wie funktioniert der Shop?',
      logout: 'Abmelden',
      reportProblem: 'Problem melden',
      termsOfUse: 'Nutzungsbedingungen',
      storybook: 'Storybook',
      disableNotification: 'Benachrichtigungen deaktivieren',
    },
    security: 'Sicherheit',
    aboutUs: 'Über uns',
    myCards: 'Meine Karten',
    more: 'Mehr',
    developer: 'Entwickler',
    taxId: 'Steuer-ID:',
    companyName: 'Firma:',
    address: 'Adresse:',
  },
  signupScreen: {
    termsPrompt: 'Mit der Anmeldung akzeptieren Sie unsere',
    termsPromptLink: 'Nutzungsbedingungen ',
    title: 'Anmelden',
    privacyPolicy: 'Datenschutzerklärung',
    privacyPolicyPrefix: 'und haben die ',
  },
  slideModal: {
    close: 'Schließen',
    searchPlaceholder: 'Artikel suchen',
  },
  notificationPermission: {
    title: '___RETAILER___ möchte Ihnen eine Benachrichtigung senden',
    text:
      'Ich möchte Push-Benachrichtigungen erhalten von ___RETAILER___ bezüglich der Funktion des automatisierten Shops.',
  },
  receiptsHome: {
    cardType: "Kreditkartentyp",
    card: "Kreditkarten",
    last4: "Die letzten 4 Ziffern der Kreditkartennummer",
    expDate: "Ablaufdatum der Kreditkarte",
    visitDate: "Datum Ihres Besuchs",
    howToFind: "Wie finde ich eine Kartennummer?",
    submit: "Quittungen zeigen",
  },
}
