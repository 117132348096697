import React from "react";
import { Text, View } from "react-native";
import { receiptLabelType, styles } from "./styles";
import { IUseFlavor, useFlavor as withFlavor } from "src/lib/hooks/useFlavor";

type IReceiptLabel = IUseFlavor & {
  receiptLabelType: receiptLabelType;
  title: string;
};

const ReceiptLabel: React.FC<IReceiptLabel> = ({
  receiptLabelType,
  title,
  flavor,
}) => {
  const css = styles(flavor, receiptLabelType);
  return (
    <View style={css.wrapper}>
      <Text style={css.title}>{title.toUpperCase()}</Text>
    </View>
  );
};

export default withFlavor(ReceiptLabel);
