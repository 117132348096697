import React from "react";
import { Linking } from "react-native";
import { NavigationParams, NavigationScreenProp } from "react-navigation";
import { Flavor } from "./flavor";
import { color2 } from "src/styles";
import { LogoCainz } from "src/components/LogoCainz";

class CainzFlavor extends Flavor {
  getName() {
    return "Cainz";
  }

  getFullName() {
    return "Cainz";
  }

  getMainColor() {
    return color2.CAINZ_MAIN;
  }

  getLinearGradientBackground() {
    return [color2.WHITE, color2.CAINZ_MAIN];
  }

  getDashLineColor() {
    return color2.CAINZ_MAIN;
  }

  getTelephone() {
    return "0495-88-7751";
  }

  getSupportEmail() {
    return "support@aifi.io";
  }

  getAddress() {
    return "埼玉県本庄市早稲田の杜1-2-1";
  }

  getTouLink() {
    return "https://policies.cainz.com/terms/service/mobilestore/";
  }

  getFaqAction(
    _navigation: NavigationScreenProp<NavigationParams, NavigationParams>
  ): void {
    Linking.openURL(
      "https://policies.cainz.com/terms/service/mobilestore/#faq"
    );
  }

  getAboutUsLink() {
    return "https://policies.cainz.com/";
  }

  getAPIEndpoint() {
    return "https://oasis-api.60-26.oasis.aifi.com";
  }

  getStripePublicKey() {
    return "";
  }

  getCircleLogo() {
    return <LogoCainz />;
  }
}

const Singleton = new CainzFlavor();
export default Singleton;
