import React from "react";
import { Flavor } from "./flavor";
import { color2 } from "src/styles";
import { LogoHEB } from "src/components/LogoHEB";

class HebFlavor extends Flavor {
  getCode() {
    return "48-17";
  }

  getName() {
    return "H-E-B";
  }

  getFullName() {
    return "H-E-B";
  }

  getMainColor() {
    return color2.HEB_RED;
  }

  getLinearGradientBackground() {
    return [color2.WHITE, color2.HEB_RED];
  }

  getDashLineColor() {
    return color2.HEB_RED;
  }

  getCircleLogo() {
    return <LogoHEB />;
  }

  getAPIEndpoint() {
    return "https://oasis-api.48-17.client.aifi.io";
  }

  getStripePublicKey() {
    return "pk_test_51ILX3HDSugCw1CsrdGMZYeBmb5xxpi9TYqP7srrQ65SeNb8HsGxZwZIcYUCcEN0B7lemHcDjLUXfaq6JShG3pAou007swfkbxy";
  }
}

const Singleton = new HebFlavor();
export default Singleton;
