import React from "react";

export const Back: React.FC<MenuIconProps> = ({ color, testId: testID }) => (
  <svg width="20" height="20" testid={testID}>
    <path
      fill={color}
      d="M10.849 1.151a1.2 1.2 0 01.087 1.6l-.087.098L4.897 8.8H18a1.2 1.2 0 01.123 2.394L18 11.2H4.897l5.952 5.951a1.2 1.2 0 01.087 1.6l-.087.098a1.2 1.2 0 01-1.6.087l-.098-.087-8-8a1.2 1.2 0 01-.087-1.6l.087-.098 8-8a1.2 1.2 0 011.698 0z"
    />
  </svg>
);
