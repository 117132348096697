import React, { Dispatch, SetStateAction } from "react";
import { AppConfig } from "../api/types";
import { IFlavor, DefaultFlavor, defaultFeatureFlags, defaultAppConfig } from "../flavors/flavorTypes";
import { IFeatureFlags } from "../../../../shared/interface";

export interface IMasterContext {
  setStoreData: (storeData: StoreData) => Promise<void>;
  flavor: IFlavor;
  setFlavor: Dispatch<SetStateAction<IFlavor>>;
  featureFlags: IFeatureFlags
  appConfig: AppConfig
  setAppConfig: Dispatch<SetStateAction<AppConfig>>
  // setFeatureFlags: Dispatch<SetStateAction<IFeatureFlags>>
  // flipFlag: (flagName: string) => void
  // stripePublicKey: string | null
  // accessibilityEnabled: boolean
  // setAccessibilityEnabled: (isEnabled: boolean) => void
}

export const defaultMasterContext: IMasterContext = {
  flavor: new DefaultFlavor(),
  featureFlags: defaultFeatureFlags,
  appConfig: defaultAppConfig,
  setAppConfig: () => {
    throw new Error("Not Implemented");
  },
  // stripePublicKey: null,
  // accessibilityEnabled: false,
  setStoreData: () => {
    throw new Error("Not Implemented");
  },
  setFlavor: () => {
    throw new Error("Not Implemented");
  },
  // setFeatureFlags: () => {
  //   throw new Error('Not Implemented')
  // },
  // flipFlag: () => {
  //   throw new Error('Not Implemented')
  // },
  // setAccessibilityEnabled: () => {
  //   throw new Error('Not Implemented')
  // },
};

export const MasterContext =
  React.createContext<IMasterContext>(defaultMasterContext);
