import React from "react";
import { Flavor } from "./flavor";
import { color2 } from "src/styles";
import { LogoVerizonUltra } from "src/components/LogoVerizonUltra";

class VerizonUltraUKFlavor extends Flavor {
  getName() {
    return "VerizonUltraUK";
  }

  getFullName() {
    return "VerizonUltraUK";
  }

  getMainColor() {
    return color2.VERIZONULTRAUK_MAIN;
  }

  getLinearGradientBackground() {
    return [color2.WHITE, color2.VERIZONULTRAUK_MAIN];
  }

  getDashLineColor() {
    return color2.VERIZONULTRAUK_MAIN;
  }

  getTelephone() {
    return "";
  }

  getSupportEmail() {
    return "support@aifi.io";
  }

  getAddress() {
    return "";
  }

  getTouLink() {
    return "http://aifi.com";
  }

  getAboutUsLink() {
    return "http://aifi.com";
  }

  getAPIEndpoint() {
    return "https://oasis-api.229-12.client.aifi.io";
  }

  getStripePublicKey() {
    return "";
  }

  getCircleLogo() {
    return <LogoVerizonUltra />;
  }
}

const Singleton = new VerizonUltraUKFlavor();
export default Singleton;
