import React from "react";

import { View, Text } from "react-native";
import { styles } from "./styles";
import { TaxBreakdownTableData } from "../types";
import { DashLine } from "src/components/v2/Dashline";

interface TaxBreakdownTableProps {
  data: TaxBreakdownTableData;
}

export const TaxBreakdownTable: React.FC<TaxBreakdownTableProps> = ({
  data,
}: TaxBreakdownTableProps) => {
  return (
    <>
      <View style={styles.row}>
        <View style={styles.column}>
          {data.headers.map(({ value }) => (
            <Text style={[styles.text, { textAlign: "left" }]}>{value}</Text>
          ))}
        </View>
        <View style={styles.column}>
          {data.netto.map(({ value }) => (
            <Text style={styles.text}>{value}</Text>
          ))}
        </View>
        <View style={styles.column}>
          {data.taxes.map(({ value }) => (
            <Text style={styles.text}>{value}</Text>
          ))}
        </View>
        <View style={styles.column}>
          {data.brutto.map(({ value }) => (
            <Text style={styles.text}>{value}</Text>
          ))}
        </View>
      </View>
      <DashLine />
    </>
  );
};