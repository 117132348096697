import { useFormik } from "formik";
import React, { PropsWithChildren, useRef } from "react";
import InputMask from "react-input-mask";
import ReCAPTCHA from "react-google-recaptcha";
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { translate } from "src/lib/i18n";
import styled from "styled-components";
import { ReceiptFormValues } from "./types";

import { loginPageSchema } from "./utils";
import { RequestStatus } from "src/lib/api/types";
import { config } from "src/lib/config";

export const ErrorMessage = styled.div`
  color: #f02849;
  text-align: left;
`;

export const SuccessMessage = styled.div`
  color: #43c9ba;
  text-align: left;
`;

export const WarningMessage = styled.div`
  color: #E9741B;
  text-align: left;
`;

const Instruction = styled.a`
  text-align: left;
  display: block;
  margin-top: 8px;
`;

interface ReceiptsFormProps {
  emailStatus: RequestStatus;
  onSubmit: (values: ReceiptFormValues) => void;
  resetEmailStatus: () => void;
}

export const ReceiptsForm: React.FC<PropsWithChildren<ReceiptsFormProps>> = ({
  emailStatus,
  resetEmailStatus,
  onSubmit,
  children,
}) => {
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const receiptsForm = useFormik({
    validateOnMount: true,
    initialValues: {
      customerPaymentInstrumentLast4: "",
      customerPaymentInstrumentExpDate: "",
      visitDate: "",
      cardType: "card",
      captchaToken: "",
      email: "",
      registerEmail: false,
    },
    validationSchema: loginPageSchema,
    onSubmit: (args: ReceiptFormValues) => {
      onSubmit(args);
      recaptchaRef?.current?.reset();
      receiptsForm.setFieldValue("captchaToken", "");
    },
  });
  const handleOnRegisterEmail = React.useCallback(
    (e: any) => {
      receiptsForm.setFieldValue("registerEmail", e.target.checked);
      if (e.target.checked === false) {
        receiptsForm.setFieldValue("email", "");
      }
    },
    [receiptsForm]
  );

  return (
    <div style={{ fontWeight: 500 }}>
      <Form
        className="row justify-content-center"
        onSubmit={receiptsForm.handleSubmit}
      >
        <FormGroup row className="justify-content-center">
          <Label for="cardType" sm={2}>
            {translate("receiptsHome.cardType")}
          </Label>
          <Col sm={3}>
            <ButtonGroup style={{ width: "100%" }}>
              <Button
                outline={receiptsForm.values.cardType !== "card"}
                onClick={() => {
                  receiptsForm.setFieldValue("cardType", "card");
                }}
              >
                {translate("receiptsHome.card")}
              </Button>
              <Button
                outline={receiptsForm.values.cardType !== "apple"}
                onClick={() => {
                  receiptsForm.setFieldValue("cardType", "apple");
                }}
              >
                Apple Pay
              </Button>
              <Button
                outline={receiptsForm.values.cardType !== "google"}
                onClick={() => {
                  receiptsForm.setFieldValue("cardType", "google");
                }}
              >
                Google Pay
              </Button>
            </ButtonGroup>
            {receiptsForm.values.cardType !== "card" ? (
              <Instruction
                target="_blank"
                href={
                  receiptsForm.values.cardType === "apple"
                    ? "/card-instruction-apple"
                    : "/card-instruction-google"
                }
                rel="noreferrer"
              >
                {translate("receiptsHome.howToFind")}
              </Instruction>
            ) : (
              ""
            )}
          </Col>
        </FormGroup>

        <FormGroup row className="justify-content-center">
          <Label for="input-cc-last-digits" sm={2}>
            {translate("receiptsHome.last4")}
          </Label>
          <Col sm={3}>
            <Input
              id="input-cc-last-digits"
              name="customerPaymentInstrumentLast4"
              placeholder="e.g. 1234"
              value={receiptsForm.values.customerPaymentInstrumentLast4}
              onChange={(e) => {
                const value = e.currentTarget.value;
                if (value.length < 5) {
                  receiptsForm.handleChange(e);
                }
              }}
              onBlur={receiptsForm.handleBlur}
              maxLength={4}
            />
            {receiptsForm.errors.customerPaymentInstrumentLast4 &&
            receiptsForm.touched.customerPaymentInstrumentLast4 ? (
              <ErrorMessage>
                {receiptsForm.errors.customerPaymentInstrumentLast4}
              </ErrorMessage>
            ) : null}
          </Col>
        </FormGroup>
        {receiptsForm.values.cardType === "card" && (
          <FormGroup row className="justify-content-center">
            <Label for="input-exp-date" sm={2}>
              {translate("receiptsHome.expDate")}
            </Label>
            <Col sm={3}>
              <Input
                id="input-exp-date"
                name="customerPaymentInstrumentExpDate"
                placeholder="MM/YY"
                value={receiptsForm.values.customerPaymentInstrumentExpDate}
                onChange={receiptsForm.handleChange}
                onBlur={receiptsForm.handleBlur}
                mask="99/99"
                tag={InputMask}
              />
              {receiptsForm.errors.customerPaymentInstrumentExpDate &&
              receiptsForm.touched.customerPaymentInstrumentExpDate ? (
                <ErrorMessage>
                  {receiptsForm.errors.customerPaymentInstrumentExpDate}
                </ErrorMessage>
              ) : null}
            </Col>
          </FormGroup>
        )}
        <FormGroup row className="justify-content-center">
          <Label for="input-visit-date" sm={2}>
            {translate("receiptsHome.visitDate")}
          </Label>
          <Col sm={3}>
            <Input
              id="input-visit-date"
              name="visitDate"
              type="date"
              value={receiptsForm.values.visitDate}
              onChange={receiptsForm.handleChange}
              onBlur={receiptsForm.handleBlur}
            />
            {receiptsForm.errors.visitDate && receiptsForm.touched.visitDate ? (
              <ErrorMessage>{receiptsForm.errors.visitDate}</ErrorMessage>
            ) : null}
          </Col>
        </FormGroup>
        <FormGroup row className="justify-content-center">
          <Col sm={5}>
            <FormGroup check>
              <Input
                id="input-show-email"
                name="registerEmail"
                type="checkbox"
                onClick={handleOnRegisterEmail}
                onBlur={receiptsForm.handleBlur}
                onChange={receiptsForm.handleChange}
              />
              <Label for="input-show-email">
                {translate("receiptsHome.registerEmail")}
              </Label>
            </FormGroup>
          </Col>
        </FormGroup>
        {receiptsForm.values.registerEmail && (
          <FormGroup row className="justify-content-center">
            <Label for="input-email" sm={2}>
              {translate("receiptsHome.email")}
            </Label>
            <Col sm={3}>
              <Input
                id="input-email"
                name="email"
                type="email"
                value={receiptsForm.values.email}
                onFocus={resetEmailStatus}
                onChange={receiptsForm.handleChange}
                onBlur={receiptsForm.handleBlur}
                placeholder="E-mail"
              />
              {receiptsForm.errors.email && receiptsForm.touched.email ? (
                <ErrorMessage>{receiptsForm.errors.email}</ErrorMessage>
              ) : emailStatus === "success" ? (
                <SuccessMessage>
                  {translate("receiptsHome.registerEmailSuccess")}
                </SuccessMessage>
              ) : emailStatus === "error" ? (
                <WarningMessage>
                  {translate("receiptsHome.registerEmailFail")}
                </WarningMessage>
              ) : null}
            </Col>
          </FormGroup>
        )}
        <Row className="justify-content-center w-auto">
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={config.RECAPTCHA_KEY}
            onChange={(token: string | null) => {
              receiptsForm.setFieldValue("captchaToken", token);
            }}
          />
        </Row>
        <Row className="justify-content-center">
          <Col sm={2} md={5}>
            {children}
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col sm={3} md={1} style={{ padding: "20px 0" }}>
            <Row>
              <Button
                type="submit"
                disabled={!receiptsForm.isValid}
                title={translate("receiptsHome.submit")}
              >
                {translate("receiptsHome.submit")}
              </Button>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
