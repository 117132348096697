import React from "react";
import { Flavor } from "./flavor";
import { color2 } from "src/styles";
import { LogoCoconut } from "src/components/LogoCoconut";

class CoconutFlavor extends Flavor {
  getCode() {
    return "237-17";
  }

  getName() {
    return "FoodsGo";
  }

  getFullName() {
    return "FoodsGo";
  }

  getMainColor() {
    return color2.COCONUT_GREEN;
  }

  getLinearGradientBackground() {
    return [color2.WHITE, color2.COCONUT_GREEN];
  }

  getDashLineColor() {
    return color2.COCONUT_GREEN;
  }

  getAPIEndpoint() {
    return "https://oasis-api.237-17.client.aifi.io";
  }

  getStripePublicKey() {
    return "pk_live_51Kj8XALb3EKKEPKaax7oXTR9xqa402WxD2VrHJndR0SX6SRIsvOMN5xbeEpH3mSspOetO6CoPjKRbTIo29J7Np5w00nejGWFeZ";
  }

  getCircleLogo() {
    return <LogoCoconut />;
  }

  getTelephone() {
    return "(309) 502-9294";
  }

  getAddress() {
    return "100 Causeway Street Boston MA 02114";
  }

  getTouLink(): string {
    return "https://www.compass-usa.com/app-privacy";
  }

  getSupportEmail() {
    return null;
  }
}

const Singleton = new CoconutFlavor();
export default Singleton;
