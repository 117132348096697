import React from "react";

export const CheckOkIcon: React.FC<MenuIconProps> = ({
  color,
  testId: testID,
}) => (
  <svg width="31" height="23" testid={testID}>
    <path
      d="M3 11.91L11.06 20 28 3"
      stroke={color}
      strokeWidth="5"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
