import React from 'react'
import DownloadIcon from './svg/download.svg'

type IconType =
  | 'DownloadIcon'

const Icons: Record<IconType, any> = {
  DownloadIcon: DownloadIcon,
}

export interface IconProps extends React.HTMLAttributes<HTMLImageElement> {
  icon: IconType
  width?: number
  height?: number
}

export const Icon: React.FC<IconProps> = ({ icon, ...props }) => {
  return <img alt={icon} src={Icons[icon]} {...props} />
}
