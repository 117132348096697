import { Flavor } from "./flavor";
import { color2 } from "src/styles";
import { LogoLCFC } from "src/components/LogoLCFC";

class LCFCFlavor extends Flavor {
  getName() {
    return "LCFC";
  }

  getFullName() {
    return "LCFC";
  }

  getMainColor() {
    return color2.LEICESTER_CITY;
  }

  getLinearGradientBackground() {
    return [color2.WHITE, color2.LEICESTER_CITY];
  }

  getDashLineColor() {
    return color2.LEICESTER_CITY;
  }

  getTelephone() {
    return "";
  }

  getSupportEmail() {
    return "northbar@compass-group.co.uk";
  }

  getAddress() {
    return "";
  }

  getTouLink() {
    return "http://aifi.com";
  }

  getAboutUsLink() {
    return "http://aifi.com";
  }

  getAPIEndpoint() {
    return "https://oasis-api.53-17.client.aifi.io";
  }

  getStripePublicKey() {
    return "";
  }

  getCircleLogo() {
    return <LogoLCFC />;
  }
}

const Singleton = new LCFCFlavor();
export default Singleton;
