import { LogoDACI } from "src/components/LogoDaci";
import { color2 } from "../../styles";
import { Flavor } from "./flavor";

class DACIFlavor extends Flavor {
  getName() {
    return "DACI UK";
  }

  getFullName() {
    return "AiFi Inc.";
  }

  getMainColor() {
    return color2.DACI;
  }

  getLinearGradientBackground() {
    return [color2.WHITE, color2.LIGHT_BLUE];
  }

  getDashLineColor() {
    return color2.DACI;
  }

  getAPIEndpoint() {
    return "";
  }

  getCircleLogo() {
    return <LogoDACI />
  }

  getStripePublicKey() {
    return "pk_test_51ILX3HDSugCw1CsrdGMZYeBmb5xxpi9TYqP7srrQ65SeNb8HsGxZwZIcYUCcEN0B7lemHcDjLUXfaq6JShG3pAou007swfkbxy";
  }
}

const Singleton = new DACIFlavor();
export default Singleton;
