import { LogoGoldenStateWarriors } from "src/components/LogoGoldenStateWarriors";
import { color2 } from "../../styles";
import { Flavor } from "./flavor";

class GoldenStateWarriorsFlavor extends Flavor {
  getName() {
    return "Golden State Warriors";
  }

  getFullName() {
    return "Golden State Warriors";
  }

  getMainColor() {
    return color2.GOLDEN_STATE_WARRIORS;
  }

  getLinearGradientBackground() {
    return [color2.WHITE, color2.GOLDEN_STATE_WARRIORS];
  }

  getDashLineColor() {
    return color2.GOLDEN_STATE_WARRIORS;
  }

  getSupportEmail() {
    return "chaserefunds@cafebonappetit.com";
  }

  getCircleLogo() {
    return <LogoGoldenStateWarriors />;
  }

  getAPIEndpoint() {
    return ''
  }

  getStripePublicKey() {
    return ''
  }
}

const Singleton = new GoldenStateWarriorsFlavor();
export default Singleton;
