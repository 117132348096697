import React from "react";
import { NavigationScreenProp, NavigationParams } from "react-navigation";
import { Linking } from "react-native";
import { Flavor } from "./flavor";
import { color2 } from "src/styles";
import { LogoBoxLL } from "src/components/LogoBoxLL";

class BoxLLFlavor extends Flavor {
  getName() {
    return "Box LL";
  }

  getFullName() {
    return "Box LL";
  }

  getMainColor() {
    return color2.BOXLL_RED;
  }

  getCircleLogo() {
    return <LogoBoxLL />;
  }

  getLinearGradientBackground() {
    return [color2.WHITE, color2.BOXLL_RED];
  }

  getDashLineColor() {
    return color2.BOXLL_RED;
  }

  getAPIEndpoint() {
    return "https://oasis-api.58-38.client.aifi.io";
  }

  getStripePublicKey() {
    return "pk_test_51ILX3HDSugCw1CsrdGMZYeBmb5xxpi9TYqP7srrQ65SeNb8HsGxZwZIcYUCcEN0B7lemHcDjLUXfaq6JShG3pAou007swfkbxy";
  }

  getTouLink() {
    return "https://www.lekkerland.de/boxll-nutzungsbedingungen/";
  }

  getFaqAction(_navigation: NavigationScreenProp<NavigationParams>) {
    Linking.openURL("https://www.lekkerland.de/boxll-datenschutz/");
  }

  getAboutUsLink() {
    return "https://www.lekkerland.de/impressum/";
  }

  getFeatureFlags() {
    return {
      ...super.getFeatureFlags(),
      isPrivacyPolicyEnabled: true,
      privacyPolicyLink: "https://www.lekkerland.de/boxll-datenschutz/",
      isDataProtectionEmailEnabled: true,
    };
  }

  getSupportEmail() {
    return "box-ll@lekkerland.com";
  }

  getTelephone(): string | null {
    return "+49 2234 1821-0";
  }

  getAddress(): string {
    return "Lekkerland SE \n Europaallee 57 \n 50226 Frechen";
  }
}

const Singleton = new BoxLLFlavor();
export default Singleton;
