import hexToRgba from "hex-to-rgba";
import { StyleSheet } from "react-native";
import { baseStyles } from "../../v21/text/BaseText";
import { color2, dpToPx } from "src/styles";
import { Flavor } from "src/lib/flavors";

export enum receiptLabelType {
  "CONTESTED",
  "UNPAID",
  "PENDING",
  "EMPTY",
  "REVIEWED",
}

const getBackround = (type: receiptLabelType) => {
  switch (type) {
    case receiptLabelType.CONTESTED:
      return hexToRgba(color2.BLUE_AIFI, 0.15);
    case receiptLabelType.UNPAID:
      return hexToRgba(color2.RED, 0.15);
    case receiptLabelType.REVIEWED:
      return hexToRgba(color2.GREEN, 0.15);
    default:
      return hexToRgba(color2.GRAY4, 0.15);
  }
};

const getColor = (type: receiptLabelType) => {
  switch (type) {
    case receiptLabelType.CONTESTED:
      return color2.BLUE_AIFI;
    case receiptLabelType.UNPAID:
      return color2.RED;
    case receiptLabelType.REVIEWED:
      return color2.GREEN;
    default:
      return color2.GRAY4;
  }
};

export const styles = (flavor: Flavor, receiptLabelType: receiptLabelType) =>
  StyleSheet.create({
    title: {
      ...baseStyles(flavor).subtitleText,
      fontSize: dpToPx(10),
      color: getColor(receiptLabelType),
    },
    wrapper: {
      borderRadius: dpToPx(5),
      backgroundColor: getBackround(receiptLabelType),
      alignItems: "center",
      justifyContent: "center",
    },
  });
