import React from "react";
// import { NavigationParams, NavigationScreenProp } from 'react-navigation'
// import { Linking } from 'react-native'
import { defaultFeatureFlags, IFlavor } from "./flavorTypes";
import { translate } from "../i18n";
import { color2 } from "../../styles";
// import SkipperJuggling from 'src/components/SkipperJuggling'
// import { OnboardingDefault } from 'src/views/Onboarding/default'
import { LogoNewAiFi } from "../../components/LogoNewAiFi";
import { font } from "src/styles/themes";
import { currencyMap } from "../constants";
import { timezoneDate } from "../utils";
import { IFeatureFlags } from "../../../../shared/interface";

export abstract class Flavor implements IFlavor {
  taxValidation(_value: string): boolean {
    return true;
  }

  getGDPRText(): string | null {
    return null;
  }

  getIconColor(): string {
    return this.getMainColor();
  }

  getSpinnerColor(): string {
    return this.getMainColor();
  }

  getDashLineColor(): string {
    return this.getMainColor();
  }

  getFaqTranslation(): string {
    return translate("settingsScreen.items.faq");
  }

  getInputBaseColor(): string {
    return color2.GRAY4;
  }

  // getFaqAction(_navigation: NavigationScreenProp<NavigationParams>) {
  //   Linking.openURL('https://aifi.com')
  // }

  formatCurrency(
    amount: string,
    currencyCode: CurrencyCode,
    _quantity?: number
  ): string {
    return `${currencyMap[currencyCode] || ""}${parseFloat(amount).toFixed(2)}`;
  }

  // getEmptyReceiptContent(): JSX.Element | null {
  //   return <SkipperJuggling />
  // }

  // getOnboarding(navigation: any): JSX.Element {
  //   return <OnboardingDefault navigation={navigation} />
  // }

  getFeatureFlags(): IFeatureFlags {
    return defaultFeatureFlags;
  }

  getSupportEmail(): string | null {
    return "support@aifi.io";
  }

  getTelephone(): string | null {
    return "1-831-254-5842";
  }

  getAddress(): string {
    return "405 El Camino Real #519\nMenlo Park, California 94025";
  }

  getAddressPlaceholder(): string {
    return "123 Some City #456, 94123 CA";
  }

  getOpenHours(): string | null {
    return null;
  }

  getAboutUsLink(): string {
    return "http://aifi.com";
  }

  getTouLink(): string {
    return "http://aifi.com";
  }

  getCircleLogo(): JSX.Element {
    return <LogoNewAiFi />;
  }

  getReceiptLogo() {
    return this.getCircleLogo()
  }

  getDefaultFontFamily(): string {
    return "System";
  }

  getPrimaryFont(): string {
    return font.family.interSemiBold;
    // return "Inter-Bold";
  }

  getPrimaryFontLight(): string {
    return font.family.interSemiBold;
    // return "Inter-SemiBold";
  }

  getPrimaryFontLightest(): string {
    return font.family.interSemiBold;
  }

  getSecondaryFont(): string {
    return "IBMPlexMono-Medium";
  }

  getSecondaryFontBold(): string {
    return "IBMPlexMono-Bold";
  }

  formatDateTime(dateTime: string, timezone: string): { date: string; time: string } {
    const date = timezoneDate(dateTime, timezone)
    const formatDate = "DD MMM YYYY";
    const formatTime = "hh:mm a";

    return {
      date: date.format(formatDate),
      time: date.format(formatTime),
    };
  }

  getStoreData(): StoreData {
    return {
      API_ENDPOINT: this.getAPIEndpoint(),
      STRIPE_PUBLISHABLE_KEY: this.getStripePublicKey(),
    };
  }

  getKeycloakEndpoint() {
    return `https://auth.${this.getCode()}.client.aifi.io`;
  }

  getCode() {
    return "CODE-NOT-CONFIGRUED";
  }

  abstract getMainColor(): string;
  abstract getLinearGradientBackground(): color2[];
  abstract getAPIEndpoint(): string;
  abstract getStripePublicKey(): string;
  abstract getName(): string;
  abstract getFullName(): string;
}
