import { Flavor } from "./flavor";
import { color2 } from "src/styles";
import { LogoClippers } from "src/components/LogoClippers";

export class ClippersFlavor extends Flavor {
  getName() {
    return "Clippers";
  }

  getFullName() {
    return "Clippers";
  }

  getMainColor() {
    return color2.DG_MAIN;
  }

  getLinearGradientBackground() {
    return [color2.WHITE, color2.DG_MAIN];
  }

  getDashLineColor() {
    return color2.DG_MAIN;
  }

  getTelephone() {
    return "";
  }

  getSupportEmail() {
    return "support@aifi.io";
  }

  getAddress() {
    return "";
  }

  getTouLink() {
    return "http://aifi.com";
  }

  getAboutUsLink() {
    return "http://aifi.com";
  }

  getAPIEndpoint() {
    return "";
  }

  getStripePublicKey() {
    return "";
  }

  getCircleLogo() {
    return <LogoClippers />;
  }
}

const Singleton = new ClippersFlavor();
export default Singleton;
