import { StyleSheet } from "react-native";
import hexToRgba from "hex-to-rgba";
import { color2 } from "../../../styles";
import { Flavor } from "../../../lib/flavors";
import { font } from "../../../styles/themes";

export const styles = (flavor: Flavor) =>
  StyleSheet.create({
    wrapper: {
      flexDirection: "row",
      alignItems: "center",
      marginBottom: 15,
      marginTop: 8,
    },
    button: {
      backgroundColor: flavor.getMainColor(),
      width: 24,
      height: 24,
      borderRadius: 12,
      overflow: "hidden",
      justifyContent: "center",
      alignItems: "center",
    },
    disable: {
      backgroundColor: hexToRgba(flavor.getMainColor(), 0.3),
    },
    quantity: {
      marginLeft: 7,
      marginRight: 7,
      fontFamily: font.family.ibmPlexMonoMedium,
      fontSize: 17,
      color: color2.BLUE_DARK,
      textAlign: "center",
    },
    text: {
      color: "#fff",
      fontSize: 24,
      lineHeight: 24,
      position: "absolute",
      top: -1.5,
    },
    trail: {
      fontFamily: font.family.ibmPlexMonoMedium,
      fontSize: 14,
      marginLeft: 8,
      flex: 1,
      textAlign: "left",
    },
  });
