import React from "react";
import { Col } from "reactstrap";
import { PoweredByAiFi } from "src/assets/icons/PoweredByAiFi";
import HeaderLogo from "src/components/HeaderLogo";
import { RequestStatus } from "src/lib/api/types";
import { useApi } from "src/lib/ApiProvider";
import { toQueryParms } from "src/utils";

import { ReceiptsForm } from "./ReceiptsForm";
import { ReceiptResult } from "./ReceiptsResult";
import { ReceiptFormValues, ReceiptInfo } from "./types";

export const Receipts = () => {
  const [receipts, setReceipts] = React.useState<ReceiptInfo[]>([]);
  const [requestStatus, setRequestStatus] =
    React.useState<RequestStatus>("idle");
  const [emailStatus, setEmailStatus] = React.useState<RequestStatus>("idle");
  const { api, authApi } = useApi();

  const handleRegisterEmail = React.useCallback(
    async (sessionToken: string, email: string) => {
      authApi
        .patch(
          `api/customer/v2/customers/me`,
          {
            email,
          },
          { headers: { authorization: `Bearer ${sessionToken}` } } // token has to be explicitely passed as it can't be taken from urlParams
        )
        .then((res) => {
          if (res.status === 200) {
            setEmailStatus("success");
          }
        })
        .catch((err) => {
          setEmailStatus("error");
          console.error(err);
        });
    },
    [authApi]
  );
  const handleSubmit = React.useCallback(
    async (values: ReceiptFormValues) => {
      setRequestStatus("loading");
      try {
        const response = await api.get(
          `api/receipts?${toQueryParms(
            values as unknown as Record<string, string>
          )}`
        );
        setReceipts(response.data);
        if (response.data.length > 0 && values.registerEmail) {
          const sessionToken = response.data[0].sessionToken;
          const email = values.email!;
          handleRegisterEmail(sessionToken, email);
        } else {
          setEmailStatus("error");
        }
        setRequestStatus("success");
      } catch (err) {
        setRequestStatus("error");
      }
    },
    [api, handleRegisterEmail]
  );
  const handleResetEmailStatus = React.useCallback(() => {
    setEmailStatus("idle");
  }, []);
  return (
    <div style={{ padding: "20px" }}>
      <HeaderLogo />
      <Col>
        <ReceiptsForm
          onSubmit={handleSubmit}
          emailStatus={emailStatus}
          resetEmailStatus={handleResetEmailStatus}
        >
          <ReceiptResult receipts={receipts} requestStatus={requestStatus} />
        </ReceiptsForm>
      </Col>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <PoweredByAiFi />
      </div>
    </div>
  );
};
