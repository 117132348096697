import React from "react";
import wallet from "src/assets/images/instructions/apple/wallet.jpg";
import card from "src/assets/images/instructions/apple/card.jpg";
import cardDots from "src/assets/images/instructions/apple/card_dots.jpg";
import cardNumber from "src/assets/images/instructions/apple/card_number.jpg";

import styled from "styled-components";
import { InstructionStep } from "./InstructionStep";

const InstructionWrapper = styled.div`
  padding: 10px 0;
  h4 {
    margin-top: 10px;
  }
  p {
    margin: 10px 0;
  }
`;

const APPLE_PAY_INSTRUCTIONS_STEPS = [
  {
    key: "step1",
    content: "Open the wallet app on your iPhone",
    image: wallet,
  },
  {
    key: "step2",
    content: 'Tap on the card and then on the "..." in the top right corner.',
    image: card,
  },
  {
    key: "step3",
    content: 'Tap on "Card Number"',
    image: cardDots,
  },
  {
    key: "step4",
    content: (
      <>
        Use last 4 digits of the <b>Apple Pay</b> card to access your receipts.
      </>
    ),
    image: cardNumber,
  },
];

export const ApplePayInstruction: React.FC = () => {
  return (
    <InstructionWrapper>
      {APPLE_PAY_INSTRUCTIONS_STEPS.map(({ content, image, key }, index) => (
        <InstructionStep
          content={content}
          image={image}
          key={key}
          header={`Step ${index + 1}`}
        />
      ))}
      <InstructionStep
        content={
          <>
            Please refer to the original{" "}
            <a href="https://support.apple.com/en-us/HT212269">documentation</a>{" "}
            provided by Apple.{" "}
          </>
        }
        header="Can't find the card number?"
      />
    </InstructionWrapper>
  );
};
