import React from "react";

export const ProductAdd: React.FC<MenuIconProps> = ({
  color,
  testId: testID,
}) => (
  <svg height="24" width="24" testid={testID}>
    <g fill="none" fillRule="evenodd" stroke={color} strokeWidth="2">
      <circle cx="12" cy="12" r="11" />
      <g strokeLinecap="round">
        <path d="M12.15 9v6.3M15.3 12.15H9" />
      </g>
    </g>
  </svg>
);
