import React from "react";
import { Flavor } from "./flavor";
import { color2 } from "src/styles";
import { LogoStreet } from "src/components/LogoStreet";

class StreetFlavor extends Flavor {
  getName() {
    return "Street Corner";
  }

  getFullName() {
    return "Street Corner";
  }

  getMainColor() {
    return color2.STREET;
  }

  getLinearGradientBackground() {
    return [color2.WHITE, color2.STREET];
  }

  getCircleLogo() {
    return <LogoStreet />;
  }

  getAPIEndpoint() {
    return "https://oasis-api.13-17.client.aifi.io";
  }

  getStripePublicKey() {
    return "pk_live_51HZMI3Bdzo9E4ktRPMSIrgWXJC5VxJnaV852Nluihp7Z4Rud5keJgem25POWo3X7GbPv28QEOTQgyEUCsNrzG0Sf00FjehxOHJ";
  }
}

const Singleton = new StreetFlavor();
export default Singleton;
