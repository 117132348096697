import { StyleSheet } from 'react-native'
import { color2 } from '../../../styles'
import { font } from '../../../styles/themes'

export const styles = StyleSheet.create({
  wrapper: {
    height: 45,
  },
  input: {
    fontFamily: font.family.ibmPlexMonoMedium,
    fontSize: 14,
    padding: 10,
    flex: 1,
  },
  borderStyle: {
    borderWidth: 1,
    borderColor: color2.INPUT_COLOR,
  },
  multiInput: {
    height: 80,
  },
  search: {
    flexDirection: 'row',
    alignItems: 'center',
  },
})
