import { Flavor } from "./flavor";
import { color2 } from "src/styles";

class LoopFlavor extends Flavor {
  getCode() {
    return "13-17";
  }

  getName() {
    return "Loop";
  }

  getFullName() {
    return "Loop";
  }

  getMainColor() {
    return color2.BLUE_AIFI;
  }

  getLinearGradientBackground() {
    return [color2.WHITE, color2.LIGHT_BLUE];
  }

  getDashLineColor() {
    return color2.LIGHT_BLUE2;
  }

  getAPIEndpoint() {
    return "https://api.13-17.client.aifi.io";
  }

  getStripePublicKey() {
    return "pk_live_1oPX8ZGPlqYgETDUpbXGcO8C00NYPX13Rh";
  }

  getFeatureFlags() {
    return {
      ...super.getFeatureFlags(),
      isOrderDetailQrCodeEnabled: true,
    };
  }
}

const Singleton = new LoopFlavor();
export default Singleton;
